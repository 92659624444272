import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


import { Rate, RateGroup, RatesDataService, TransactionsGet } from '@backbase/rates-data';


@Injectable()
export class WidgetFhlbRatesNgService {

  //Error message when the API server is down or API giving exception.
  errorMessage = new Subject<boolean>();
  currentErrorMessage = this.errorMessage.asObservable();

  constructor(private readonly data: RatesDataService) { }

  /**
   * This function will return the rates for that Product when we pass the product name as a parameter.
   * @param ratesParams 
   */
  getRates(ratesParams: any, headers: HttpHeaders): Observable<any> {
    return this.data.getRates(ratesParams,headers).pipe(map((response: HttpResponse<TransactionsGet>): any => {
      this.errorMessage.next(false);
      return response.body
        ? response.body.advRateGroups : [];
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
      return of();
    }));
  }
}

