import { Component, OnInit } from '@angular/core';
import { WidgetFhlbSearchNgService } from './widget-fhlb-search-ng.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'fhlb-widget-fhlb-search-ng',
  templateUrl: "./../search-page.html",
  styles: []
})
export class WidgetFhlbSearchNgComponent implements OnInit {
  searchList: any;
  suggestURL:any;
  searchQuery:any;
  searchParams: any = {};
  showErrorMessage: any;
  pagenationDetails: any = {}

  constructor(private searchDataService: WidgetFhlbSearchNgService,private cookieService: CookieService) { }

  ngOnInit() {
    //this.getselectedfilter(this.searchParams);
    if (localStorage.getItem("searchFilter") != null) {
      this.searchQuery = localStorage.getItem("searchFilter");
      localStorage.removeItem("searchFilter");
      this.searchParams = {};
    Object.assign(this.searchParams, { "query": this.searchQuery});
      this.getselectedfilter(this.searchParams);
    }
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken); 
    this.suggestURL = this.searchDataService.getSuggestsURL(this.searchParams, headers);
  }

  /**
 * Geting Advance list based on the filter Params.
 * @param filterParams 
 */
  getselectedfilter(filterParams: any) {
    Object.assign(filterParams, { "size": 10 });
    Object.assign(this.pagenationDetails, { "size": 10 });
    Object.assign(filterParams, { "portalContext": "fhlbank-boston" });
    if (filterParams.from){
      Object.assign(this.pagenationDetails, { "from": filterParams.from });
      var offset=filterParams.from*10;
      Object.assign(filterParams, { "offSet": offset});
    }
    else{
      Object.assign(this.pagenationDetails, { "from": 0 });
      Object.assign(filterParams, { "offSet": 0});
    }
    
    this.searchParams = filterParams;
if(!filterParams.suggestion){
  let xsrfToken = this.cookieService.get('XSRF-TOKEN');
  let headers= new HttpHeaders();
  headers = headers.append('X-XSRF-TOKEN', xsrfToken);
  this.searchList = this.searchDataService.getSearchList(filterParams, headers);
}
    this.showErrorMessage = this.searchDataService.currentErrorMessage;
  }
}
