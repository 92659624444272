import { Component, Input, OnInit, EventEmitter, Output, ViewChildren, ElementRef, QueryList, SimpleChanges } from '@angular/core';
import { WidgetFhlbNewsNgService } from './widget-fhlb-news-ng.service';

@Component({
  selector: "fhlb-news-filter",
  templateUrl: "./../news-narrow-list.html",
  providers: [WidgetFhlbNewsNgService]
})

export class FhlbankNewsFilterComponent implements OnInit {
  @Input() newsFilterParams: any;
  @Output() selectedfiltername = new EventEmitter();
  @Input() pagenationParamsDetails: any;
  @Input() newsList:any;
  narrowListfilter: any = {};
  yearList: any;
  newsCount=0;
  paramsObject: any = {};
  isClearAllDisabled: boolean = true;

  // Boolean value to verify whether the accordion is open or not.
  isCollapse0: boolean = true;
  isCollapse1: boolean = true;
  isCollapse2: boolean = true;

  //Selected Filter values from Narrow list.
  monthParams: string[] = [];

  @ViewChildren("filterCheckboxes")
  filterCheckboxes!: QueryList<ElementRef>;
  //checkedfilters: any = [];


  constructor(private newsDataService: WidgetFhlbNewsNgService) {
  }

  /**
   * Function to clear all the narrow list filter and get the default API to fetch the result.
   */
  clearfilters() {
    if (!this.CheckParamsEmpty()) {
      this.filterCheckboxes.forEach((element) => {
        element.nativeElement.checked = false;
      });
      this.monthParams = [];
      this.paramsObject = {
      };
      this.selectedfiltername.emit(this.paramsObject);
      var pageScrollToTop = document.getElementById('news-result');
      if (pageScrollToTop != null) {
        pageScrollToTop.scrollIntoView();
      }
    }
    this.isClearAllDisabled = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['newsList']) {
      if (this.newsList != null) {
          this.newsCount = this.newsList;
      }else{
        this.newsCount =0
      }
    }
  }

  ngOnInit() {
    let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var years = [];
    this.yearList = [];
    var totalMonths = 11;

    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth();
    for (var i = 0; i <= 2; i++) {
      let year = currentYear - i;
      this.yearList.push(year);
      if (year == currentYear)
        totalMonths = currentMonth;
      else
        totalMonths = 11;
      var months = [];
      for (var j = 0; j <= totalMonths;) {
        j = j + 1;
        var filterFormat = year + '-' + j;
        months.push({
          "name": monthNames[j - 1],
          "value": filterFormat
        });
      }
      years[year] = months.reverse();
    }
    Object.assign(this.narrowListfilter, years);
    this.paramsObject = this.newsFilterParams;
    this.isClearAllDisabled = this.CheckParamsEmpty();
  }

  /**
   * Action on selecting the filters in Narrow list
   * @param e 
   * @param filterHeader 
   * @param value 
   */
  selectedFilter(e: { target: { checked: boolean; value: string }; }, value: string) {
    this.paramsObject = {};
    if (e.target.checked) {
      this.monthParams.push(e.target.value)
    } else {
      var index = this.monthParams.indexOf(e.target.value);
      if (index !== -1) this.monthParams.splice(index, 1);
    }
    if (this.monthParams.length !== 0)
      Object.assign(this.paramsObject, { "articleDate": this.monthParams });
    this.selectedfiltername.emit(this.paramsObject);
    var pageScrollToTop = document.getElementById('news-result');
    if (pageScrollToTop != null) {
      pageScrollToTop.scrollIntoView();
    }
    this.isClearAllDisabled = this.CheckParamsEmpty();
  }

  /**
   * To open the selected filter header and collapsing other headers.
   * @param filterHeader 
   */
  toggleClicked(filterHeader: any) {
    switch (filterHeader) {
      case 0:
        this.isCollapse0 = !this.isCollapse0;
        this.isCollapse1 = true;
        this.isCollapse2 = true;
        break;
      case 1:
        this.isCollapse1 = !this.isCollapse1;
        this.isCollapse0 = true;
        this.isCollapse2 = true;
        break;
      case 2:
        this.isCollapse2 = !this.isCollapse2;
        this.isCollapse0 = true;
        this.isCollapse1 = true;
        break;
      default:
        break;
    }
  }

  checkcollapsed(params: any) {
    switch (params) {
      case 0:
        return this.isCollapse0;
      case 1:
        return this.isCollapse1;
      case 2:
        return this.isCollapse2;
      default:
        break;
    }

  }

  getTotalCount(){
    var resultCountDetails = "";
    if (this.newsCount == 1) {
      resultCountDetails = "Showing " + this.newsCount + " result";
    } else if (this.newsCount <= this.pagenationParamsDetails.size) {
      resultCountDetails = "Showing " + this.newsCount + " results";
    } else {
    var start = (this.pagenationParamsDetails.from*this.pagenationParamsDetails.size)+1;
    var end = this.pagenationParamsDetails.size*(this.pagenationParamsDetails.from+1)
    if(end >= this.newsCount){
      end= this.newsCount;
    }
      resultCountDetails = "showing " + start+"-" + end +" of "+this.newsCount+" results";
  }
    return resultCountDetails;
  }

  /**
  * Check if the filter list is empty to enable and disable clear button
  */
  CheckParamsEmpty() {
    if (Object.entries(this.paramsObject).length === 0 && this.paramsObject.constructor === Object) {
      return true;
    } else {
      return false;
    }
  }

}
