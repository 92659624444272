import { Component, Input, OnInit, EventEmitter, Output, ViewChildren, ElementRef, QueryList, SimpleChanges } from '@angular/core';
import { WidgetFhlbStrategiesNgService } from './widget-fhlb-strategies-ng.service';

@Component({
  selector: "fhlb-strategies-filter",
  templateUrl: "./../strategies-narrow-list.html",
  providers: [WidgetFhlbStrategiesNgService]
})

export class FhlbankStrategiesFilterComponent implements OnInit {
  @Input() strategiesFilterParams: any;
  @Output() selectedfiltername = new EventEmitter();
  @Input() pagenationParamsDetails: any;
  @Input() sIList: any;
  sICount = 0
  narrowList: any;
  paramsObject: any = {};
  isClearAllDisabled: boolean = true;

  //Array list for displaying contnet Type, Member Type and Funding Goals.
  ContentTypeList: any;
  MemberTypesList: any;
  FundingGoalsList: any;

  // Boolean value to verify whether the accordion is open or not.
  isContentTypeCollapse: boolean = true;
  isMemberTypesCollapse: boolean = true;
  isFundingGoalsCollapse: boolean = true;

  //Selected Filter values from Narrow list.
  contentTypeParams: string[] = [];
  memberTypesParams: string = '';
  fundingGoalsParams: string[] = [];

  @ViewChildren("filterCheckboxes")
  filterCheckboxes!: QueryList<ElementRef>;
  //checkedfilters: any = [];


  constructor(private strategiesDataService: WidgetFhlbStrategiesNgService) {
  }

  /**
   * Function to clear all the narrow list filter and get the default API to fetch the result.
   */
  clearfilters() {
    if (!this.CheckParamsEmpty()) {
      this.filterCheckboxes.forEach((element) => {
        element.nativeElement.checked = false;
      });
      this.contentTypeParams = [];
      this.memberTypesParams = '';
      this.fundingGoalsParams = [];
      this.paramsObject = {
      };
      this.selectedfiltername.emit(this.paramsObject);
    }
    this.isClearAllDisabled = true;
  }

  getTotalCount() {
    var resultCountDetails = "";
    if (this.sICount == 1) {
      resultCountDetails = "Showing " + this.sICount + " result";
    } else if (this.sICount <= this.pagenationParamsDetails.size) {
      resultCountDetails = "Showing " + this.sICount + " results";
    } else {
      var start = (this.pagenationParamsDetails.from * this.pagenationParamsDetails.size) + 1;
      var end = this.pagenationParamsDetails.size * (this.pagenationParamsDetails.from + 1)
      if (end >= this.sICount) {
        end = this.sICount;
      }
      resultCountDetails = "Showing " + start + "-" + end + " of " + this.sICount + " results";
    }
    return resultCountDetails;
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['sIList']) {
      if (this.sIList != null) {
          this.sICount = this.sIList;
      }else{
        this.sICount =0
      }
    }
  }

  ngOnInit() {
    this.narrowList = this.strategiesDataService.strategiesNarrowList;
    this.paramsObject = this.strategiesFilterParams;
    this.isClearAllDisabled = this.CheckParamsEmpty();
    // Converting Narrow list of array of each block
    for (var i = 0; i < this.narrowList.length; i++) {
      var keyObject = Object.keys(this.narrowList[i]);
      for (var j = 0; j < keyObject.length; j++) {
        switch (keyObject[j]) {
          case "ContentType":
            this.ContentTypeList = this.narrowList[i].ContentType;
            break;
          case "MemberTypes":
            this.MemberTypesList = this.narrowList[i].MemberTypes;
            break;
          case "FundingGoals":
            this.FundingGoalsList = this.narrowList[i].FundingGoals;
            break;
          default:
            break;
        }
      }
    }
  }

  /**
   * Action on selecting the filters in Narrow list
   * @param e 
   * @param filterHeader 
   * @param value 
   */
  selectedFilter(e: { target: { checked: boolean; value: string }; }, filterHeader: string, value: string) {
    this.paramsObject = {};
    var localmemberType = this.memberTypesParams;
    switch (filterHeader) {
      case "ContentType":
        if (e.target.checked) {
          this.contentTypeParams.push(e.target.value)
        } else {
          var index = this.contentTypeParams.indexOf(e.target.value);
          if (index !== -1) this.contentTypeParams.splice(index, 1);
        }
        break;
      case "MemberTypes":
        this.memberTypesParams = value;
        break;
      case "FundingGoals":
        if (e.target.checked) {
          this.fundingGoalsParams.push(e.target.value)
        } else {
          var index = this.fundingGoalsParams.indexOf(e.target.value);
          if (index !== -1) this.fundingGoalsParams.splice(index, 1);
        }
        break;
      default:
        break;
    }
    if (this.contentTypeParams.length !== 0)
      Object.assign(this.paramsObject, { "contentType": this.contentTypeParams });
    if (this.memberTypesParams !== '')
      Object.assign(this.paramsObject, { "memberTypes": this.memberTypesParams });
    if (this.fundingGoalsParams.length !== 0)
      Object.assign(this.paramsObject, { "fundingGoals": this.fundingGoalsParams });

    if (!(filterHeader === "MemberTypes" && value === localmemberType)) {
      this.selectedfiltername.emit(this.paramsObject);
    }
    this.isClearAllDisabled = this.CheckParamsEmpty();
  }

  /**
   * To open the selected filter header and collapsing other headers.
   * @param filterHeader 
   */
  toggleClicked(filterHeader: string) {
    switch (filterHeader) {
      case "ContentType":
        this.isContentTypeCollapse = !this.isContentTypeCollapse;
        this.isMemberTypesCollapse = true;
        this.isFundingGoalsCollapse = true;
        break;
      case "MemberTypes":
        this.isMemberTypesCollapse = !this.isMemberTypesCollapse;
        this.isContentTypeCollapse = true;
        this.isFundingGoalsCollapse = true;
        break;
      case "FundingGoals":
        this.isFundingGoalsCollapse = !this.isFundingGoalsCollapse;
        this.isContentTypeCollapse = true;
        this.isMemberTypesCollapse = true;
        break;
      default:
        break;
    }

  }

  /**
  * Check if the filter list is empty to enable and disable clear button
  */
  CheckParamsEmpty() {
    if (Object.entries(this.paramsObject).length === 0 && this.paramsObject.constructor === Object) {
      return true;
    } else {
      return false;
    }
  }

}
