import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { WidgetFhlbStrategiesNgComponent } from './widget-fhlb-strategies-ng.component';
import {FhlbankStrategiesFilterComponent } from './fhlbank-strategies-filter.component';
import { FhlbankStrategiesListComponent} from './fhlbank-strategies-list.component';
import{WidgetFhlbStrategiesNgService}  from './widget-fhlb-strategies-ng.service';
import { FhlbUiComponentsLibModule } from '@backbase/fhlb-ui-components-lib';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,FormsModule,
    HttpClientModule,
    FhlbUiComponentsLibModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbStrategiesNgComponent }
    })
  ],
  declarations: [WidgetFhlbStrategiesNgComponent, FhlbankStrategiesFilterComponent, FhlbankStrategiesListComponent],
  providers: [WidgetFhlbStrategiesNgService]
})
export class WidgetFhlbStrategiesNgModule { }
