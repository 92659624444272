import { Component, OnInit, Input, AfterViewChecked } from '@angular/core';
import { WidgetFhlbAdvancesFeaturesNgService } from './widget-fhlb-advances-features-ng.service';
import { ItemModel } from '@backbase/core-ang';

declare var $: any;
@Component({
  selector: 'fhlb-widget-fhlb-advances-features-ng',
  templateUrl: "./../advance-details-page.html",
  styles: []
})
export class WidgetFhlbAdvancesFeaturesNgComponent implements OnInit, AfterViewChecked {
  advanceDetails!: any;
  showErrorMessage: any;

  public ngAfterViewChecked(): void {
    /* need _canScrollDown because it triggers even if you enter text in the textarea */
    if ($(".advance-wrapper").length > 0) {
      $(".advance-sidebar-mapping > div")
        .children()
        .appendTo(".advance-editable-templates-2");
    }
    if ($(".advance-wrapper").length > 0) {
      $(".advance-editable-templates-1 > div")
        .children()
        .appendTo(".advance-wrapper");
    }
  }

  constructor(private advanceService: WidgetFhlbAdvancesFeaturesNgService) {

  }

  ngOnInit() {
    // get the Advance Details from Advance Widget component.
    this.advanceDetails = this.advanceService.currentAdvanceDetail;
    this.showErrorMessage = this.advanceService.currentErrorMessage;
    window.scrollTo(0, 0);

  }
}
