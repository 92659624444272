import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import {  Observable,  Subject, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';



import {  RatesDataService, TransactionsGet } from '@backbase/rates-data';


@Injectable()
export class WidgetFhlbHistoricalRatesNgService {

  //Error message when the API server is down or API giving exception.
  errorMessage = new Subject<boolean>();
  currentErrorMessage = this.errorMessage.asObservable();

  downloadErrorMessage = new Subject<boolean>();
  currentDownloadErrorMessage = this.downloadErrorMessage.asObservable();

  constructor(private readonly data: RatesDataService) { }

  /**
   * This function will return the rates for that Product when we pass the product name as a parameter.
   * @param ratesParams 
   */
  getRates(ratesParams: any, headers: HttpHeaders): Observable<any> {
    return this.data.getRates(ratesParams, headers).pipe(map((response: HttpResponse<TransactionsGet>): any => {
      this.errorMessage.next(false);
      return response.body
        ? response.body.advRateGroups : [];
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
      return of();
    }));
  }

  getRatesHealth(ratesParams: any, headers: HttpHeaders): Observable<any> {
    return this.data.getRatesHealth(ratesParams, headers).pipe(map((response: HttpResponse<TransactionsGet>): any => {
      this.errorMessage.next(false);
      return response.status;
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
      return of(err.status);
    }));
  }

  getdownLoadBoolean(downLoadBoolean : any){
    this.downloadErrorMessage.next(downLoadBoolean);

  }
  getDownloadRates(ratesParams: any, headers: HttpHeaders): Observable<any> {
    return this.data.getRatesDownload(ratesParams,headers).pipe(map((response: HttpResponse<TransactionsGet>): any => {
      this.errorMessage.next(false);
      return response.body
        ? response.body.advRateGroups : [];
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
      return of();
    }));
  }

  getBusinessDayValidation(ratesParams: any, headers: HttpHeaders): Observable<any> {
    return this.data.getCheckBusinessDay(ratesParams,headers).pipe(map((response: HttpResponse<TransactionsGet>): any => {
      this.errorMessage.next(false);
      return response.body
        ? response.body : [];
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
      return throwError(err);
    }));
  }

  getDownloadRatesURL(ratesParams: any, headers: HttpHeaders) {
    return this.data.getRatesDownloadUrl(ratesParams, headers) ;
  }
}

