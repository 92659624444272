import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FhlbErrorMessageComponent } from './fhlb-error-message.component';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ FhlbErrorMessageComponent ],
  exports: [ FhlbErrorMessageComponent ]
})
export class FhlbErrorMessageModule { }
