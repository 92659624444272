import { Component, OnInit, Input, Output, AfterViewInit, EventEmitter, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { WidgetFhlbankCompareAdvanceNgService, AdvanceDetails } from './widget-fhlbank-compare-advance-ng.service';
import { Router } from '@angular/router';
import { $ } from 'protractor';



@Component({
  selector: 'fhlb-compare-list',
  templateUrl: "./../compare-list.html"
})
export class FhlbankCompareListComponent implements OnInit {
  @Input() compareLists: Array<AdvanceDetails> = []
  @Input() params: any;
  @Output() updatedParams = new EventEmitter();
  //@ViewChild('normalTd') viewChild: ElementRef;
  localCompareList: any = [];
  columnSize: string = 'col-sm-3';
  productHeaderList = ["fundingGoal", "rateOutlook", "fundingType", "termLimitations", "minimumAmount", "interestPayments", "principalDue", "putable", "availability", "prepaymentEligible"]
  productList: any = {};
  contentHeight: any;
  rows: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['compareLists']) {
      if (this.compareLists != null) {
        this.updateProductList();
      }
    }
  }

  /**
   * Updated product list after removing from list.
   */
  updateProductList() {
    this.localCompareList = [];
    for (var i = 0; i < this.compareLists.length; i++) {
      for (var j = 0; j < this.params["advanceNames"].length; j++) {
        if (this.params["advanceNames"][j].localeCompare(this.compareLists[i].productName) === 0) {
          this.localCompareList.push(this.compareLists[i])
        }
      }
    }
    this.getcompareListCount(this.params["advanceNames"].length); // To get the number of product available for comparison
    this.separateHeader();
  }

  separateHeader() {
    for (var i = 0; i < this.productHeaderList.length; i++) {
      var tempVariable: any = []
      for (var j = 0; j < this.localCompareList.length; j++) {
        if (Object.keys(this.localCompareList[j]).indexOf(this.productHeaderList[i]) !== -1) {
          tempVariable.push(this.localCompareList[j][this.productHeaderList[i]])
        } else {
          tempVariable.push("");
        }
      }
      this.productList[this.productHeaderList[i]] = tempVariable;
    }
  }

  /**
   * Remove the product from list.
   * @param value 
   */
  removeProduct(value: any) {
    if (this.params["advanceNames"].length !== 1) {
      var index = this.params["advanceNames"].indexOf(value);
      if (index !== -1) {
        this.params["advanceNames"].splice(index, 1);
      }
      this.updateProductList()
    } else {
      var advanceURL = window.BB.config.linkRoot + "/" + window.BB.config.portalName + "/products-programs/advances"
      window.location.href = advanceURL;
    }
  }

  /**
   * Function to redirect to selected Advance Page.
   * @param advanceName 
   */
  redirectProductPage(advanceName: any) {
    localStorage.setItem("SelectedAdvance", JSON.stringify(advanceName));
    var advanceURL = window.BB.config.linkRoot + "/" + window.BB.config.portalName + "/" + advanceName.productName.toLowerCase().replace(/[^a-zA-Z-._0-9 ]/g, "").replace(/[ ]/g,"-").replace(/--+/g, '-');
    //window.location.href = advanceURL;
    return advanceURL;
  }

  /**
   * Function to allocate the column based on no of products
   * @param value 
   */
  getcompareListCount(value: any) {
    switch (value) {
      case 1:
        this.columnSize = 'col-sm-12'
        break;
      case 2:
        this.columnSize = 'col-sm-6'
        break;
      case 3:
        this.columnSize = 'col-sm-4'
        break;
      case 4:
        this.columnSize = 'col-sm-3'
        break;
      default:
        this.columnSize = 'col-sm-3'
        break;
    }

  }
  /**
   * function to find if is it list or string to display in table.
   * @param element 
   */
  showUlTag(element: any) {
    if (typeof element === "string") {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Find the header component to separate the headers
   * @param title 
   */
  getproductHeaderName(title: any) {
    var updatedTitle = "";
    switch (title) {
      case "fundingGoal":
        updatedTitle = 'Funding Goals'
        break;
      case "rateOutlook":
        updatedTitle = 'Rate Outlook'
        break;
      case "fundingType":
        updatedTitle = 'Funding Type'
        break;
      case "termLimitations":
        updatedTitle = 'Term Limitations'
        break;
      case "minimumAmount":
        updatedTitle = 'Minimum'
        break;
      case "interestPayments":
        updatedTitle = 'Interest Payments'
        break;
      case "principalDue":
        updatedTitle = 'Principal Due'
        break;
      case "putable":
        updatedTitle = 'Putable/Callable'
        break;
      case "availability":
        updatedTitle = 'Availability'
        break;
      case "prepaymentEligible":
        updatedTitle = 'Prepayment Eligible'
        break;
      default:
        updatedTitle = ' '
        break;
    }
    return updatedTitle;
  }

  constructor(private data: WidgetFhlbankCompareAdvanceNgService, public router: Router) { }

  ngOnInit() {
  }

}
