import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList, ElementRef, Directive, HostListener, SimpleChanges } from '@angular/core';
import { WidgetFhlbankAdvancePageNgService, AdvanceDetails } from './widget-fhlbank-advance-page-ng.service';

@Component({
  selector: "fhlb-advance-filter",
  templateUrl: "./../narrow-list.html",
  providers: [WidgetFhlbankAdvancePageNgService]
})

export class FhlbankAdvanceFilterComponent implements OnInit {
  @Input() advance: any;
  @Input() advanceFilterParams: any;
  @Output() selectedfiltername = new EventEmitter();
  @ViewChildren("filterCheckboxes")
  filterCheckboxes!: QueryList<ElementRef>;
  AdvanceCount = 0;
  checkedfilters: any = [];

  isFundinggoalsCollapse: boolean = true;
  isRateoutlookCollapse: boolean = true;
  isFundingTypeCollapse: boolean = true;
  isPutableCollapse: boolean = true;
  narrowList: any;
  // Array list to show in Narrow filter
  fundingArray: any;
  fundingtypeArray: any;
  rateoutlookeArray: any;
  putableArray: any;
  isClearAllDisabled: boolean = true; // disable clear All filter
  fundingGoalParams: string[] = [];
  rateOutlookParams: string[] = [];
  fundingTypeParams: string = '';
  putableParams: string = '';
  paramsObject: any = {
  };
  http: any;
  results: any;

  /**
 * Accordian action in Narrow list header
 * @param filterHeader 
 */
  toggleClicked(filterHeader: string) {
    switch (filterHeader) {
      case "fundingGoal":
        this.isFundinggoalsCollapse = !this.isFundinggoalsCollapse;
        this.isRateoutlookCollapse = true;
        this.isFundingTypeCollapse = true;
        this.isPutableCollapse = true;
        break;
      case "rateOutlook":
        this.isRateoutlookCollapse = !this.isRateoutlookCollapse;
        this.isFundinggoalsCollapse = true;
        this.isFundingTypeCollapse = true;
        this.isPutableCollapse = true;
        break;
      case "fundingType":
        this.isFundingTypeCollapse = !this.isFundingTypeCollapse;
        this.isFundinggoalsCollapse = true;
        this.isRateoutlookCollapse = true;
        this.isPutableCollapse = true;
        break;
      case "putable":
        this.isPutableCollapse = !this.isPutableCollapse;
        this.isFundinggoalsCollapse = true;
        this.isRateoutlookCollapse = true;
        this.isFundingTypeCollapse = true;
        break;
      default:
        break;
    }

  }

  /**
   * Action on selecting the filters in Narrow list
   * @param e 
   * @param filterHeader 
   * @param value 
   */
  selectedFilter(e: { target: { checked: boolean; value: string }; }, filterHeader: string, value: string) {
    this.paramsObject = {};
    var localfundingtype = this.fundingTypeParams;
    var localputabletype = this.putableParams;
    switch (filterHeader) {
      case "fundingGoal":
        if (e.target.checked) {
          this.fundingGoalParams.push(e.target.value)
        } else {
          var index = this.fundingGoalParams.indexOf(e.target.value);
          if (index !== -1) this.fundingGoalParams.splice(index, 1);
        }
        break;
      case "rateOutlook":
        if (e.target.checked) {
          this.rateOutlookParams.push(e.target.value)
        } else {
          var index = this.rateOutlookParams.indexOf(e.target.value);
          if (index !== -1) this.rateOutlookParams.splice(index, 1);
        }
        break;
      case "fundingType":
        this.fundingTypeParams = value;
        break;
      case "putable":
        this.putableParams = value;
        break;
      default:
        break;
    }
    if (this.fundingGoalParams.length !== 0)
      Object.assign(this.paramsObject, { "fundingGoal": this.fundingGoalParams });
    if (this.rateOutlookParams.length !== 0)
      Object.assign(this.paramsObject, { "rateOutlook": this.rateOutlookParams });
    if (this.fundingTypeParams !== '')
      Object.assign(this.paramsObject, { "fundingType": this.fundingTypeParams });
    if (this.putableParams !== '')
      Object.assign(this.paramsObject, { "putable": this.putableParams });
    if (!(filterHeader === "fundingType" && value === localfundingtype)) {
      if (!(filterHeader === "putable" && value === localputabletype)) {
        this.selectedfiltername.emit(this.paramsObject);
      }
    }
    this.isClearAllDisabled = this.CheckParamsEmpty();
  }

  /**
   * Check if the fliet list is empty to enable and disable clear button
   */
  CheckParamsEmpty() {
    if (Object.entries(this.paramsObject).length === 0 && this.paramsObject.constructor === Object) {
      return true;
    } else {
      return false;
    }
  }

  getPreSelectedList(filterHeader: any, value: any) {
    var fundingGoalList = this.paramsObject.fundingGoal;
    var rateoutlookeArray = this.paramsObject.rateOutlook;
    switch (filterHeader) {
      case "fundingGoal":
        if (fundingGoalList !== undefined) {
          for (var i = 0; i < fundingGoalList.length; i++) {
            if (fundingGoalList[i] === value) {
              var index = this.fundingGoalParams.indexOf(fundingGoalList[i]);
              if (index === -1) {
                this.fundingGoalParams.push(fundingGoalList[i]);
              }
              return true;
            } else {
              return false;
            }
          }
        }
        break;
      case "rateOutlook":
                if (rateoutlookeArray !== undefined) {
                  for (var i = 0; i < rateoutlookeArray.length; i++) {
                    if (rateoutlookeArray[i] === value) {
                      var index = this.rateOutlookParams.indexOf(rateoutlookeArray[i]);
                      if (index === -1) {
                        this.rateOutlookParams.push(rateoutlookeArray[i]);
                      }
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
                break;
      case "fundingType":
        break;
      case "putable":
        break;
      default:
        break;
    }
    return false;
  }

  /***
   * Clear All will clear all the filter list and make an API to fetch entire Advance details.
   */
  clearfilters() {
    if (!this.CheckParamsEmpty()) {
      this.filterCheckboxes.forEach((element) => {
        element.nativeElement.checked = false;
      });
      this.fundingGoalParams = [];
      this.rateOutlookParams = [];
      this.fundingTypeParams = '';
      this.putableParams = '';
      this.paramsObject = {
      };
      this.selectedfiltername.emit(this.paramsObject);
    }
    this.isClearAllDisabled = true;
  }

  constructor(private advanceDataService: WidgetFhlbankAdvancePageNgService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.AdvanceCount = 0;
    if (changes['advance']) {
      if (this.advance != null) {
        this.AdvanceCount = this.advance;
      }
      else {
        this.AdvanceCount = 0;
      }
    }
  }

  getTotalCount() {
    var resultCountDetails = "";
    if (this.AdvanceCount == 1) {
      resultCountDetails = "Showing " + this.AdvanceCount + " product";

    } else {
      resultCountDetails = "Showing " + this.AdvanceCount + " products";
    }
    return resultCountDetails;
  }

  ngOnInit() {
    this.paramsObject = this.advanceFilterParams;
    //if (this.paramsObject.fundingGoal !== null) {
    if (typeof this.paramsObject.fundingGoal != "undefined" && this.paramsObject.fundingGoal != null && this.paramsObject.fundingGoal.length != null
      && this.paramsObject.fundingGoal.length > 0) {
      this.isFundinggoalsCollapse = false;
    }
    this.isClearAllDisabled = this.CheckParamsEmpty();
    this.narrowList = this.advanceDataService.narrowList;
    // Converting Narrow list of array of each block
    for (var i = 0; i < this.narrowList.length; i++) {
      var keyObject = Object.keys(this.narrowList[i]);
      for (var j = 0; j < keyObject.length; j++) {
        switch (keyObject[j]) {
          case "fundingGoal":
            this.fundingArray = this.narrowList[i].fundingGoal;
            break;
          case "fundingType":
            this.fundingtypeArray = this.narrowList[i].fundingType;
            break;
          case "rateOutlook":
            this.rateoutlookeArray = this.narrowList[i].rateOutlook;
            break;
          case "putable":
            this.putableArray = this.narrowList[i].putable;
            break;
          default:
            break;
        }
      }
    }
  }
}
