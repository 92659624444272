import { Component, OnInit, ViewChild } from '@angular/core';
import { WidgetFhlbankAdvancePageNgService, AdvanceDetails } from './widget-fhlbank-advance-page-ng.service';
import { Observable } from 'rxjs';
import { FhlbankAdvanceListComponent } from './fhlbank-advance-list.component';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: "fhlb-widget-fhlbank-advance-page-ng",
  templateUrl: "./../advances-page.html"
})

export class WidgetFhlbankAdvancePageNgComponent implements OnInit {
  @ViewChild(FhlbankAdvanceListComponent, {static: false}) child: any;
  advanceList!: Observable<AdvanceDetails>;
  advanceListParams: any = {};
  showErrorMessage: any;
  totalAdvanceCount:any;

  constructor(private advanceDataService: WidgetFhlbankAdvancePageNgService,private cookieService: CookieService) {
    
  }

  ngOnInit() {
    // To get the initial Advance List from an API

    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);

    this.advanceListParams = {};
    var filterArray = [];
    if (localStorage.getItem("advanceFilter") !== null) {
      filterArray.push(localStorage.getItem("advanceFilter"));
      this.advanceListParams.fundingGoal = filterArray;
      localStorage.removeItem("advanceFilter");
    }
    this.advanceList = this.advanceDataService.getItems(this.advanceListParams,headers);
    this.showErrorMessage = this.advanceDataService.currentErrorMessage;
    this.totalAdvanceCount = this.advanceDataService.currentTotalCount;
  }

  /**
   * Geting Advance list based on the filter Params.
   * @param filterParams 
   */
  getselectedfilter(filterParams: any) {
    
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.advanceList = this.advanceDataService.getItems(filterParams, headers);
    this.showErrorMessage = this.advanceDataService.currentErrorMessage;
    this.totalAdvanceCount = this.advanceDataService.currentTotalCount;
  }
}

