import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { WidgetFhlbRatesPageNgComponent } from './widget-fhlb-rates-page-ng.component';
import { FhlbUiComponentsLibModule } from '@backbase/fhlb-ui-components-lib';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { WidgetFhlbRatesNgService } from './widget-fhlb-rates-ng.service';
import { FhlbankRatesDetailsComponent } from './fhlb-rates-details.component';
import {FhlbankRatesDownloadComponent} from './fhlb-rates-download.component'
import { from } from 'rxjs';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    FhlbUiComponentsLibModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbRatesPageNgComponent }
    })
  ],
  declarations: [WidgetFhlbRatesPageNgComponent, FhlbankRatesDetailsComponent,FhlbankRatesDownloadComponent],
  providers: [WidgetFhlbRatesNgService]
})
export class WidgetFhlbRatesPageNgModule { }


