import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

 
 import {SecDataService, SecZipDetailsResponse, SecFilingDetailsResponse,SecFilingDetails} from '@backbase/sec-data';

@Injectable()
export class WidgetFhlbInvestorSecNgService {

  errorMessage = new Subject<boolean>();
  currentErrorMessage = this.errorMessage.asObservable();



  constructor(private readonly data: SecDataService) { }

  /**
   * This function will return the Strategies for that Product when we pass the product name as a parameter.
   * @param StrategiesParams 
   */
  getSecListingList(SecListParams: any, headers: HttpHeaders): Observable<any> {
    return this.data.getSecDetails(SecListParams, headers).pipe(map((response: HttpResponse<SecFilingDetailsResponse>): any => {
      this.errorMessage.next(false);
      return response.body
      ? response.body.secDetails : [];
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
        return of();
    }));
  }

  getZipDownload( params:any, headers: HttpHeaders,fileName:any ): Observable<any>  {
    return this.data.getSecDetailsZipContents(params, headers).pipe(map((response: HttpResponse<any>): any => {
      this.errorMessage.next(false);
      return [response, fileName];
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
        return of();
    }));
  }
}

