import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'fhlb-rates-details',
  templateUrl: "./../rates-details.html",
  styles: []
})
export class FhlbankRatesDetailsComponent implements OnInit {
  @Input() rateListDetails: any;
  @Input() rateProductName:any;
  @Output() rateListAPI = new EventEmitter();
  selectedRateDetails: any;
  currentRates:any;
  params:any;

  /**
   * Function to display the date and time in formatted 
   * @param effectiveDate 
   */
  getFormatedDate(effectiveDate: any){
    var dateFormat = require('dateformat');
    var d = new Date(effectiveDate);
    return dateFormat(d, "mmmm dd, yyyy");
  }

  getRatesDateFormat(term: any){
    var formattedTerm = term.replace("yr", "YEAR").replace("wk", "WEEK").replace("mo", "MONTH");
    return formattedTerm;

  }
  /**
   * Function to get the result based on Selected Terms
   * @param selectedTerm 
   */
  onTermSelected(selectedTerm: any) {
    this.params={};
    Object.assign(this.params, { "term": selectedTerm});
    this.rateListAPI.emit(this.params);
}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rateListDetails']) {
    }

  }

  constructor() { 

  }
  
  ngOnInit() { 
    this.selectedRateDetails = this.rateListDetails[0];
    this.currentRates = this.selectedRateDetails.rates[0];
  }
}
