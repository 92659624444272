import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { WidgetFhlbInvestorSecNgService } from './widget-fhlb-investor-sec-ng.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'fhlb-investor-sec-list',
  templateUrl: "./../investor-sec-filling-table.html"
})
export class FhlbankInvestorSecListComponent implements OnInit {
  
  @Input() secListDetails: any;
  downloadPath:any;
  dummyTD: any= [1,2,3,4]
  ngOnChanges(changes: SimpleChanges) {

  }

  downloadFile(year: any, quarter: any, fileName: any) {
    let params: any = {};
    params.year = year;
    params.quarter = quarter;
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers = new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.downloadPath = this.investorDataService.getZipDownload( params, headers,fileName ).subscribe(
      (response: any) => {
        var url = window.URL.createObjectURL(response[0].body);
        var filename = response[1];
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response[0].body,filename);
        }
        else {
            var a: any = document.createElement('A');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
      });
  }

  constructor(private cd: ChangeDetectorRef,private investorDataService: WidgetFhlbInvestorSecNgService, private cookieService: CookieService) { }
  ngOnInit() {
 

  }



}

