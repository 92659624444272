import { Component, OnInit } from '@angular/core';
import { WidgetFhlbRatesNgService } from './widget-fhlb-rates-ng.service';
import { ItemModel } from '@backbase/core-ang';
import { WidgetFhlbAdvancesFeaturesNgService } from '@backbase/widget-fhlb-advances-features-ng';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'fhlb-widget-fhlb-rates-ng',
  templateUrl: "./../rates-page.html",
  styles: []
})
export class WidgetFhlbRatesNgComponent implements OnInit {
  ratesList!: any;
  showErrorMessage: any;
  defaultParams:any;
  productName: any;
  ratesParams: any = {};
  values: any;
  rateOptions: any;

  constructor(private advanceDataService: WidgetFhlbAdvancesFeaturesNgService, private ratesService: WidgetFhlbRatesNgService, 
    private model: ItemModel,private cookieService: CookieService) { }

  ngOnInit() {
    this.ratesParams = {};
    this.defaultParams={};
    // Get the Rates Type from Preference whether to show rats for display default image.
    this.model.property("rateType").subscribe(
      (rateOptions) => {
        this.rateOptions = rateOptions;
        if (rateOptions != "no-rates") {
          // Get the Product Name from preference to display rates.
          this.model.property("productName").subscribe(
            (productName) => {
              this.productName = productName;
              this.ratesParams.productName = productName;
              // Default Terms Year from Preference to get the initial API.

              if (rateOptions === "with-rates") {
                this.model.property("terms").subscribe(
                  (term) => {
                    this.defaultParams.term = term;
                    this.ratesParams.term = this.defaultParams.term;
                    this.getRatesDetails(this.ratesParams);
                  });

              } else if (rateOptions === "amortizing") {
                this.model.property("terms").subscribe(
                  (term) => {
                    this.defaultParams.term = term;
                    this.ratesParams.term = this.defaultParams.term;
                    this.model.property("amort").subscribe(
                      (amort) => {
                        this.defaultParams.amort = amort;
                    this.ratesParams.amort = this.defaultParams.amort;
                        this.getRatesDetails(this.ratesParams);
                      });
                  });

              } else if (rateOptions === "member-option") {
                this.model.property("maturity").subscribe(
                  (maturity) => {
                    this.defaultParams.maturity = maturity;
                    this.ratesParams.maturity = this.defaultParams.maturity;
                    this.model.property("put").subscribe(
                      (put) => {
                        this.defaultParams.put = put;
                    this.ratesParams.put = this.defaultParams.put;
                        this.getRatesDetails(this.ratesParams);
                      });
                  });
              }

            });
        }
      });
    this.showErrorMessage = this.ratesService.currentErrorMessage;
  }

  /**
   * Function to get the Rates Details from API.
   * @param selectedParams 
   */
  getRatesDetails(selectedParams: any) {
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.ratesList = this.ratesService.getRates(selectedParams,headers);
    this.showErrorMessage = this.ratesService.currentErrorMessage;
  }

  /**
   * Function to get the selected terms from the dropdown. 
   * @param selectedTerm 
   */
  getSelectedTerm(selectedTerm: any) {
    this.ratesParams = {};
    Object.assign(selectedTerm, { "productName": this.productName });
    //this.ratesParams.productName = this.productName;
    //this.ratesParams.term = selectedTerm;
    this.getRatesDetails(selectedTerm);
  }

}
