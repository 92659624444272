import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, Output, EventEmitter, AfterViewChecked } from '@angular/core';
import { CompleterService, RemoteData } from 'ng2-completer';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders, HttpRequest } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'fhlb-search-header-query',//fhlb-search-list
  templateUrl: "./search-header-query.html"
})
export class FhlbankSearchHeaaderQueryComponent implements OnInit, AfterViewChecked {
  @Input() suggestformaturl: any;
  @Output() selectedfiltername = new EventEmitter();
  errorMessage: boolean = false;
  SuggestdataService: RemoteData | undefined;
  paramsObject: any = {};
  http: any;
  headers:any;


  protected suggestID: string = "";

  searchQuery(e: any) {
    if (e.length > 2) {
      localStorage.setItem("searchFilter", e);
      var serachResultURL = window.BB.config.linkRoot + "/" + window.BB.config.portalName + "/search-result";
      window.location.href = serachResultURL;
    }
  }

  onClick(event: any) {
    if (event.target.textContent != "") {
      this.searchQuery(this.suggestID);
    }
  }

  onKeyup(event: any) {
    if (event.key == "Enter") {
      this.searchQuery(this.suggestID);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  constructor(private cd: ChangeDetectorRef, private completerService: CompleterService,private cookieService: CookieService) {
  }
  ngOnInit() {
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.SuggestdataService = this.completerService.remote(null);
    this.SuggestdataService.urlFormater(term => {
      const url = this.suggestformaturl + "query=" + term + "&portalContext=fhlbank-boston"
      return url;
    });

   let options = {
      headers:  headers
   }
    this.SuggestdataService.requestOptions(options);
    this.SuggestdataService.dataField('suggestResultItems');
  }

  public ngAfterViewChecked(): void {

    if ($(".search-header-box > .completer-holder").length > 0) {
      if ($(".search-header-box > .completer-holder label").length === 0) {
        $("<label for='searchbox' class='ax-hidden'>Search</label>").insertBefore("input");
        $(".search-header-box > .completer-holder input").attr('id', 'searchbox');
        $(".search-header-box > .completer-holder input").attr('name', 'searchbox');
        $(".search-header-box > .completer-holder input").attr('aria-labelledby', 'searchbox');
      }
      $(".search-header-box > .completer-holder label").remove("label[for='search-result-box']");
    }

    /* need _canScrollDown because it triggers even if you enter text in the textarea */
    if ($(".search-header-results-wrapper").length > 0) {
      if ($(".search-suggestion-header-list").find(".static-search").length > 0) {
        $(".search-suggestion-header-list").empty();
      }
      $(".search-header-result-page > div")
        .children()
        .appendTo(".search-suggestion-header-list");

    }
  }
}
