import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { WidgetFhlbEventsNgService } from './widget-fhlb-events-ng.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

declare var $: any;
@Component({
  selector: 'fhlb-widget-fhlb-events-ng',
  templateUrl: "./../events-page.html",
  styles: []
})
export class WidgetFhlbEventsNgComponent implements OnInit, AfterViewChecked {

  eventsList: any;
  eventsParams: any = {};
  showErrorMessage: any;
  pagenationDetails: any = {}
  public width = 0;
  greaterwidth: boolean = false;

  constructor(private strategiesDataService: WidgetFhlbEventsNgService,private cookieService: CookieService) { }

  ngOnInit() {
    this.getselectedfilter(this.eventsParams);
  }

  /**
* Geting Advance list based on the filter Params.
* @param filterParams 
*/
  getselectedfilter(filterParams: any) {
    Object.assign(filterParams, { "size": 6 });
    Object.assign(filterParams, { "contentType": "EVENTS" });

    Object.assign(this.pagenationDetails, { "size": 6 });
    if (filterParams.from)
      Object.assign(this.pagenationDetails, { "from": filterParams.from });
    else
      Object.assign(this.pagenationDetails, { "from": 0 });
    this.eventsParams = filterParams;
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.eventsList = this.strategiesDataService.getEventsList(filterParams, headers);
    this.showErrorMessage = this.strategiesDataService.currentErrorMessage;
  }

  public ngAfterViewChecked(): void {

    if ($(".events-mapping").length > 0) {
      $(".events-right-side-section > div")
        .children()
        .appendTo(".events-mapping");
    }
  }


}
