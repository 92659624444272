import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { WidgetFhlbEventsNgComponent } from './widget-fhlb-events-ng.component';
import { FhlbUiComponentsLibModule } from '@backbase/fhlb-ui-components-lib';
import { FhlbankEventsListComponent} from './fhlbank-events-list.component';
import{WidgetFhlbEventsNgService}  from './widget-fhlb-events-ng.service';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,FormsModule,
    HttpClientModule,
    FhlbUiComponentsLibModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbEventsNgComponent }
    })
  ],
  declarations: [WidgetFhlbEventsNgComponent,FhlbankEventsListComponent],
  providers: [WidgetFhlbEventsNgService]
})
export class WidgetFhlbEventsNgModule { }


