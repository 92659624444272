import { createMocks } from '@backbase/foundation-ang/data-http';
import { Provider } from '@angular/core';
import { SEARCH_DATA_CONFIG } from './search-data.service';
const examples = [
    {
        "urlPattern": "/{version}/index",
        "method": "POST",
        "responses": [
            {
                "status": 200,
                "body": null,
                "type": "ApiIndexResponse"
            }
        ]
    },
    {
        "urlPattern": "/{version}/index",
        "method": "DELETE",
        "responses": [
            {
                "status": 200,
                "body": null,
                "type": "ApiIndexResponse"
            }
        ]
    },
    {
        "urlPattern": "/{version}/search",
        "method": "GET",
        "responses": [
            {
                "status": 200,
                "body": null,
                "type": "SearchResponse"
            }
        ]
    },
    {
        "urlPattern": "/{version}/sitemap",
        "method": "GET",
        "responses": [
            {
                "status": 200,
                "body": null,
                "type": "SiteMapResponseList"
            }
        ]
    },
    {
        "urlPattern": "/{version}/suggest",
        "method": "GET",
        "responses": [
            {
                "status": 200,
                "body": null,
                "type": "SuggestResponse"
            }
        ]
    },
    {
        "urlPattern": "/{version}/index/status",
        "method": "GET",
        "responses": [
            {
                "status": 200,
                "body": null,
                "type": "ApiIndexStatus"
            }
        ]
    }
];
export const SearchDataMocksProvider: Provider = createMocks(examples, SEARCH_DATA_CONFIG);
