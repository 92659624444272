import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'bb-fhlb-loader-ui',
  templateUrl: './fhlb-loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FhlbLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}