import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { FhlbUiComponentsLibModule } from '@backbase/fhlb-ui-components-lib';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { WidgetFhlbHistoricalRatesNgComponent } from './widget-fhlb-historical-rates-ng.component';
import { FhlbankHistoricalRatesCalendarComponent } from './fhlb-historical-rates-calendar.component';
import { WidgetFhlbHistoricalRatesNgService} from './widget-fhlb-historical-rates-ng.service';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    MyDatePickerModule ,
    ReactiveFormsModule,
    FormsModule,
    FhlbUiComponentsLibModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbHistoricalRatesNgComponent }
    })
  ],
  declarations: [WidgetFhlbHistoricalRatesNgComponent, FhlbankHistoricalRatesCalendarComponent],
  providers: [WidgetFhlbHistoricalRatesNgService]
})
export class WidgetFhlbHistoricalRatesNgModule { }






