import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { AdvanceDetails, WidgetFhlbAdvancesFeaturesNgService } from '../../widget-fhlb-advances-features-ng/src/widget-fhlb-advances-features-ng.service'


@Component({
  selector: 'fhlb-advance-header',
  templateUrl: "./../advance-header.html",
  styles: []
})
export class FhlbankAdvanceHeaderComponent implements OnInit {
  @Input() advanceFeatureList: Array<AdvanceDetails> = [];
  selectedAdvance: any;
  rateOutLookImages: any = {}
  @Input() currentPageTitle: any;
  pageTitle: any;

  ngOnChanges(changes: SimpleChanges) {
  }

  updateAdvanceDetails() {
    //Update Advance Details when we get the advance from API.
    this.advanceService.updatedAdvance(this.selectedAdvance);
  }
  
  constructor(private advanceService: WidgetFhlbAdvancesFeaturesNgService) {
  }

  ngOnInit() {
    if (this.advanceFeatureList != null) {
      if (this.advanceFeatureList.length !== 0) {
        for (var j = 0; j < this.advanceFeatureList.length; j++) {
          if (this.advanceFeatureList[j].productName === this.currentPageTitle) {
            this.selectedAdvance = this.advanceFeatureList[j];
            this.updateAdvanceDetails();
          }
        }
      }
    }

  }
}
