import { Component, Input, OnInit, EventEmitter, Output, ViewChildren, ElementRef, QueryList, SimpleChanges } from '@angular/core';
import { WidgetFhlbFormsPageNgService } from './widget-fhlb-forms-page-ng.service';

@Component({
  selector: "fhlb-forms-filter",
  templateUrl: "./../forms-narrow-list.html",
  providers: [WidgetFhlbFormsPageNgService]
})

export class FhlbankFormsFilterComponent implements OnInit {
  @Input() formsFilterParams: any;
  @Output() selectedfiltername = new EventEmitter();
  @Input() pagenationParamsDetails: any;
  @Input() formsList:any;
  formsCount=0;
  narrowListfilter: any = {};
  narrowList: any;
  paramsObject: any = {};
  isClearAllDisabled: boolean = true;

  //Array list for displaying contnet Type, Member Type and Funding Goals.
  FormCategoryList: any;
  MemberTypesList: any;
  StateListArray: any;

  // Boolean value to verify whether the accordion is open or not.
  isFormCategoryCollapse: boolean = true;
  isMemberTypesCollapse: boolean = true;
  isStateCollapse: boolean = true;

  //Selected Filter values from Narrow list.
  formCategoryParams: string = '';
  preSelectedValue:string='';
  memberTypesParams: string = '';
  stateParams: string[] = [];

  @ViewChildren("filterCheckboxes")
  filterCheckboxes!: QueryList<ElementRef>;
  //checkedfilters: any = [];


  constructor(private formsDataService: WidgetFhlbFormsPageNgService) {
  }

  /**
   * Function to clear all the narrow list filter and get the default API to fetch the result.
   */
  clearfilters() {
    if (!this.CheckParamsEmpty()) {
      this.filterCheckboxes.forEach((element) => {
        element.nativeElement.checked = false;
      });
      this.formCategoryParams = '';
      this.memberTypesParams = '';
      this.stateParams = [];
      this.paramsObject = {
      };
      this.selectedfiltername.emit(this.paramsObject);
    }
    this.isClearAllDisabled = true;
  }
  
  getTotalCount(){
    var resultCountDetails = "";
    if (this.formsCount == 1) {
      resultCountDetails = "Showing " + this.formsCount + " result";
    } else if (this.formsCount <= this.pagenationParamsDetails.size) {
      resultCountDetails = "Showing " + this.formsCount + " results";
    } else {
      var start = (this.pagenationParamsDetails.from*this.pagenationParamsDetails.size)+1;
    var end = this.pagenationParamsDetails.size*(this.pagenationParamsDetails.from+1)
    //showing 1-8 of {{sICount}} results
    if(end >= this.formsCount){
      end= this.formsCount;
    }
    resultCountDetails = "showing " + start+"-" + end +" of "+this.formsCount+" results";
  }
    return resultCountDetails;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formsList']) {
      if (this.formsList != null) {
          this.formsCount = this.formsList;
      }else{
        this.formsCount =0
      }
    }
  }

  getPreSelectedList(filterHeader: any, value: any) {
    var formCategoryList = this.paramsObject.formCategory;
    var StateListArray = this.paramsObject.State;
    switch (filterHeader) {
      case "FormCategory":
        if (formCategoryList !== undefined) {
            if (formCategoryList === value) {
              return "checked";
            } else {
              return false;
            }
        }
        break;
          case "State":
              if (StateListArray !== undefined) {
                for (var i = 0; i < StateListArray.length; i++) {
                  if (StateListArray[i] === value) {
                    var index = this.StateListArray.indexOf(StateListArray[i]);
                    if (index === -1) {
                      this.stateParams.push(StateListArray[i]);
                    }
                    return true;
                  } else {
                    return false;
                  }
                }
              }
              break;
      default:
        break;
    }
    return false;
  }


  ngOnInit() {
    this.narrowList = this.formsDataService.formsNarrowList;
    this.paramsObject = this.formsFilterParams;
    if (typeof this.paramsObject.formCategory != "undefined" && this.paramsObject.formCategory != null) {
      this.isFormCategoryCollapse = false;
      this.formCategoryParams = this.paramsObject.formCategory;
      this.preSelectedValue = this.paramsObject.formCategory;

    }
    this.isClearAllDisabled = this.CheckParamsEmpty();
    // Converting Narrow list of array of each block
    for (var i = 0; i < this.narrowList.length; i++) {
      var keyObject = Object.keys(this.narrowList[i]);
      for (var j = 0; j < keyObject.length; j++) {
        switch (keyObject[j]) {
          case "FormCategory":
            this.FormCategoryList = this.narrowList[i].FormCategory;
            break;
          case "MemberTypes":
            this.MemberTypesList = this.narrowList[i].MemberTypes;
            break;
          case "State":
            this.StateListArray = this.narrowList[i].State;
            break;
          default:
            break;
        }
      }
    }
  }

  /**
   * Action on selecting the filters in Narrow list
   * @param e 
   * @param filterHeader 
   * @param value 
   */
  selectedFilter(e: { target: { checked: boolean; value: string }; }, filterHeader: string, value: string) {
    this.paramsObject = {};
    var localmemberType = this.memberTypesParams;
    var localformCategory = this.formCategoryParams;
    switch (filterHeader) {
      case "FormCategory":
        this.formCategoryParams = value;
        break;
      case "MemberTypes":
        this.memberTypesParams = value;
        break;
      case "State":
        if (e.target.checked) {
          this.stateParams.push(e.target.value)
        } else {
          var index = this.stateParams.indexOf(e.target.value);
          if (index !== -1) this.stateParams.splice(index, 1);
        }
        break;
      default:
        break;
    }
    if (this.formCategoryParams !== '')
      Object.assign(this.paramsObject, { "formCategory": this.formCategoryParams });
    if (this.memberTypesParams !== '')
      Object.assign(this.paramsObject, { "memberType": this.memberTypesParams });
    if (this.stateParams.length !== 0)
      Object.assign(this.paramsObject, { "state": this.stateParams });

    if (filterHeader === "State") {
      this.selectedfiltername.emit(this.paramsObject);
    } else {
      if (filterHeader === "MemberTypes") {
        if( value !== localmemberType)
          this.selectedfiltername.emit(this.paramsObject);
      }else{
        if( value !== localformCategory)
          this.selectedfiltername.emit(this.paramsObject);
      }
    }
    this.isClearAllDisabled = this.CheckParamsEmpty();
  }

  /**
   * To open the selected filter header and collapsing other headers.
   * @param filterHeader 
   */
  toggleClicked(filterHeader: string) {
    switch (filterHeader) {
      case "FormCategory":
        this.isFormCategoryCollapse = !this.isFormCategoryCollapse;
        this.isMemberTypesCollapse = true;
        this.isStateCollapse = true;
        break;
      case "MemberTypes":
        this.isMemberTypesCollapse = !this.isMemberTypesCollapse;
        this.isFormCategoryCollapse = true;
        this.isStateCollapse = true;
        break;
      case "State":
        this.isStateCollapse = !this.isStateCollapse;
        this.isFormCategoryCollapse = true;
        this.isMemberTypesCollapse = true;
        break;
      default:
        break;
    }

  }

  /**
  * Check if the filter list is empty to enable and disable clear button
  */
  CheckParamsEmpty() {
    if (Object.entries(this.paramsObject).length === 0 && this.paramsObject.constructor === Object) {
      return true;
    } else {
      return false;
    }
  }

}
