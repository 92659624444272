import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { Inject, InjectionToken } from "@angular/core";
import { ServiceDataHttpConfig } from "@backbase/foundation-ang/data-http";
import * as Data from "./rates-data.interfaces";
const version = 'v1', normalizeHttpParameter = (accum: NormalizedHttpParameters, [key, value]: [string, string | string[] | undefined | number]) => {
    if (value === undefined) {
        return accum;
    }
    if (typeof value === 'number') {
        return { ...accum, [key]: String(value) };
    }
    return { ...accum, [key]: value };
}, normalizeHttpParameters = (params: HttpParameters = {}): NormalizedHttpParameters => Object.entries(params).reduce(normalizeHttpParameter, {});
export const RATES_DATA_CONFIG = new InjectionToken("ServiceDataHttpConfig");
@Injectable({
    providedIn: 'root'
})
export class RatesDataService {
    constructor(private readonly http: HttpClient, 
    @Inject(RATES_DATA_CONFIG)
    private readonly config: ServiceDataHttpConfig) { }
    getRates(params: {
        term?: string;
        productName?: string;
        amort?: string;
        maturity?: string;
        put?: string;
        isHistoricalPage?: string;
        businessDate?: string;
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.TransactionsGet>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/rates`;
        return this.http.request<Data.TransactionsGet>('get', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }

    getRatesHealth(params: {
        term?: string;
        productName?: string;
        amort?: string;
        maturity?: string;
        put?: string;
        isHistoricalPage?: string;
        businessDate?: string;
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.TransactionsGet>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/production-support/health`;
        return this.http.request<Data.TransactionsGet>('get', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }


    getRatesDownloadUrl(params: {
        fileType?: string;
        isHistoricalPage?: string;
        businessDate?: string;
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<any>> {     
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/ratesDownload`;
        return this.http.request<Blob>('get', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'blob' as 'json',
            withCredentials: false,
        });
    }

    getRatesDownload(params: {
        fileType?: string;
        isHistoricalPage?: string;
        businessDate?: string;
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<any>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/ratesDownload`;
        return this.http.request<any>('get', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    getCheckBusinessDay(params: {
        businessDate?: string;
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.BusinessdayResponse>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/checkBusinessDay`;
        return this.http.request<Data.BusinessdayResponse>('get', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
}
interface NormalizedHttpParameters {
    [k: string]: string | string[];
}
interface HttpParameters {
    [k: string]: string | string[] | undefined | number;
}
