/**
* Wrapped data endpoints in corresponding methods in the data-module service are available.
1. Data interfaces are declared (basically it's a set of a wire types for the widget), so the data format is clearly defined.
2. Ability to mock the data (based on examples provided within RAML specification).
3. Data-model extensions technique, which allows you to pass extra data from the backend (i.e. custom fields on top of the default API contract).
* @author -Mindtree
*/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { WidgetFhlbAdvancesFeaturesNgComponent } from './widget-fhlb-advances-features-ng.component';
import { WidgetFhlbAdvancesFeaturesNgService } from './widget-fhlb-advances-features-ng.service';
import { FhlbankAdvanceFeaturesComponent } from './fhlb-advance-features.component'
import { FhlbUiComponentsLibModule } from '@backbase/fhlb-ui-components-lib';

@NgModule({
  imports: [
    CommonModule,
    FhlbUiComponentsLibModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbAdvancesFeaturesNgComponent }
    })
  ],
  declarations: [WidgetFhlbAdvancesFeaturesNgComponent, FhlbankAdvanceFeaturesComponent],
  providers: [WidgetFhlbAdvancesFeaturesNgService]
})
export class WidgetFhlbAdvancesFeaturesNgModule { }
