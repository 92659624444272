import { createMocks } from '@backbase/foundation-ang/data-http';
import { Provider } from '@angular/core';
import { SI_NEWS_EVENTS_DATA_CONFIG } from './si-news-events-data.service';
const examples = [
    {
        "urlPattern": "/{version}/strategies/details",
        "method": "GET",
        "responses": []
    },
    {
        "urlPattern": "/{version}/strategies/add",
        "method": "POST",
        "responses": []
    },
    {
        "urlPattern": "/{version}/strategies/update",
        "method": "PUT",
        "responses": []
    },
    {
        "urlPattern": "/{version}/strategies/delete",
        "method": "DELETE",
        "responses": []
    },
    {
        "urlPattern": "/{version}/news/details",
        "method": "GET",
        "responses": []
    },
    {
        "urlPattern": "/{version}/news/add",
        "method": "POST",
        "responses": []
    },
    {
        "urlPattern": "/{version}/news/update",
        "method": "PUT",
        "responses": []
    },
    {
        "urlPattern": "/{version}/news/delete",
        "method": "DELETE",
        "responses": []
    }
];
export const SiNewsEventsDataMocksProvider: Provider = createMocks(examples, SI_NEWS_EVENTS_DATA_CONFIG);
