import { Component, OnInit } from '@angular/core';
import { WidgetFhlbankCompareAdvanceNgService } from './widget-fhlbank-compare-advance-ng.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

declare var $: any;
@Component({
  selector: 'fhlb-widget-fhlbank-compare-advance-ng',
  templateUrl: "./../compare-page-main.html",
  styles: []
})
export class WidgetFhlbankCompareAdvanceNgComponent implements OnInit {
  compareList: any = [];
  localCompareList: any;
  params: any = {};
  showErrorMessage: any;
  constructor(private compareService: WidgetFhlbankCompareAdvanceNgService,private cookieService: CookieService) { }
  remove() {

  }

  /**
   * Get filtered values to pass it for an API.
   * @param value 
   */
  getupdatedParamsfilter(value: any) {
    this.params = value;
    localStorage.setItem("compareList", JSON.stringify(this.params.advanceNames));
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.compareList = this.compareService.getCompareList(value, headers);
    this.showErrorMessage = this.compareService.currentErrorMessage;
  }

  ngOnInit() {
    $(document).ready(() => {
      $(".main-table").clone(true).appendTo('#table-scroll').addClass('clone');
    });
    if (localStorage.getItem("compareList") === null) {
      var advanceURL = window.BB.config.linkRoot + "/" + window.BB.config.portalName + "/products-programs/advances"
      window.location.href = advanceURL;
    } else {
      this.localCompareList = localStorage.getItem("compareList");
      this.params.advanceNames = JSON.parse(this.localCompareList);
      let xsrfToken = this.cookieService.get('XSRF-TOKEN');
      let headers= new HttpHeaders();
      headers = headers.append('X-XSRF-TOKEN', xsrfToken);
      this.compareList = this.compareService.getCompareList(this.params, headers);
      this.showErrorMessage = this.compareService.currentErrorMessage;
    }
  }
}
