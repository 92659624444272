/**
* Mocks provider should be added to the providers list of the app.
*  But we shall also consider the fact that mocks aren't supposed to be available on production environments.
*  Due to that fact we want them to be included via environment variables mechanism that angular provides us with.
* Let's have a look on environment files in our reference widget:
* @author -Mindtree
*/
import { createMocks } from '@backbase/foundation-ang/data-http';
import { Provider } from '@angular/core';
import { ADVANCE_DATA_CONFIG } from './advance-data.service';
const examples = [
    {
        "urlPattern": "/{version}/advances/details",
        "method": "GET",
        "responses": [
            {
                "status": 200,
                "body": {
                    "advanceProducts": [
                        {
                            "productName": "Amortizing Advance",
                            "description": "Whether a financial institution is looking to fund mortgages or investments or cover short-term funding gaps, the Amortizing Advance can be structured to meet specific funding needs and manage unpredictable cash flows",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steepening",
                                "Rising"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$1 or $2M for putable/structured advances",
                            "interestPayments": "First business day of the month",
                            "principalDue": "At maturity",
                            "putable": "Yes or No",
                            "availability": "Same day/next day depending on time of day",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Capped Floater Advance",
                            "description": "Whether facing a rising- or fall-rate environment, the Capped Floater Advance is ideal for managing the balance sheet",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals ",
                            "principalDue": "At maturity",
                            "putable": "Yes or No",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Classic Advance",
                            "description": "Offering maturity options across the curve, the popular Classic Advance serves as the core building block of your asset-liability management strategy.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$1",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "No",
                            "availability": "Same day",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Classic Plus Cap Advance",
                            "description": "Financial institutions seeking long-term protection against rising rates and the potential to decrease interest expense when rates rise will find that the Classic Plus Cap Advance is an ideal funding option.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "One- or three-month intervals",
                            "principalDue": "At maturity",
                            "putable": "No",
                            "availability": "Same day",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Collar Advance",
                            "description": "An important tool for liability-sensitive financial institutions seeking to manage-interest rate risk, the Collar Advance combines an interest-rate cap with an interest-rate floor feature.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Steeping",
                                "Rising"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals ",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Corridor Advance",
                            "description": "Financial institutions exposed to rising interest rates find the Corridor Advance a cost-effective solution to mitigate the risk and manage short-term balance-sheet interest-rate sensitivity. ",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals ",
                            "principalDue": "At maturity",
                            "putable": "Yes or No",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Curve Flattener Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Flat",
                                "Flattening"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals ",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Curve Steepener Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Steeping",
                                "Rising"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals ",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Daily Cash Manager Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "One day",
                            "minimumAmount": "$2M",
                            "interestPayments": "At maturity/next business day",
                            "principalDue": "At maturity/next business day",
                            "putable": "No",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "No"
                        },
                        {
                            "productName": "Discount Note Auction-Floater Advance",
                            "description": "An ideal product for asset-sensitive financial institutions, the Discount Note Auction-Floater Advance can be used to improve liquidity ratios and manage interest-rate risk, while offering the ability to prepay without a fee.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals ",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Next day",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Expander Advance",
                            "description": "Providing the flexibility to reevaluate your funding requirements with the option to double the advance amount at the original interest rate, this advance can be particularly attractive to financial institutions that are uncertain of the cash flows stemming from their mortgage portfolios.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "Yes or No",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Flipper Advance",
                            "description": "Generally used in flat yield curve, the Flipper Advance helps manage interest-rate risk sensitivity while offering the opportunity to convert to a fixed-rate advance.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Flat",
                                "Flattening"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Same day",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Flipper Advance with Guaranteed Flip",
                            "description": "A floating- to fixed-rate advance, the Flipper Advance With Guaranteed Flip is an ideal balance sheet funding tool to enhance net interest margins that can also be used as a hedge against rising interest rates.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Flat",
                                "Flattening"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "No",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "HLB-Option Advance",
                            "description": "The Classic Product",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Flat",
                                "Flattening"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "IDEAL Cash Manager Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "One day",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "No",
                            "availability": "One day",
                            "prepaymentEligible": "No"
                        },
                        {
                            "productName": "Knockout Advance",
                            "description": "Using this advance, financial institutions can save more on their borrowing costs by selling the option to cancel at a predetermined date prior to maturity.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Flat",
                                "Flattening"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Member-Option Advance",
                            "description": "Appealing to asset-sensitive financial institutions and those that are unsure of their liquidity needs, this product offers the ability to lock in funding with the option to prepay without a fee.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Three-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Rollover Cash Management Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "One day",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "Flexible",
                            "putable": "No",
                            "availability": "One day",
                            "prepaymentEligible": "No"
                        },
                        {
                            "productName": "Slider Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "SOFR-Indexed Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Match funds adjustable-rate assets",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Annually",
                            "principalDue": "At maturity",
                            "putable": "Yes or No",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Symmetrical Prepayment Advance",
                            "description": "An ideal advance option for financial institutions seeking flexibility and diversification in supporting net income and maximizing earnings.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month or semi-annually",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Next day",
                            "prepaymentEligible": "Yes"
                        }
                    ]
                },
                "type": "AdvanceDetailsGET"
            }
        ]
    },
    {
        "urlPattern": "/{version}/advances/add",
        "method": "POST",
        "responses": [
            {
                "status": 200,
                "body": null,
                "type": "AdvanceDetailsResponse"
            }
        ]
    },
    {
        "urlPattern": "/{version}/advances/update",
        "method": "PUT",
        "responses": [
            {
                "status": 200,
                "body": null,
                "type": "AdvanceDetailsResponse"
            }
        ]
    },
    {
        "urlPattern": "/{version}/advances/delete",
        "method": "DELETE",
        "responses": [
            {
                "status": 200,
                "body": null,
                "type": "AdvanceDetailsResponse"
            }
        ]
    },
    {
        "urlPattern": "/{version}/advances/compareDetails",
        "method": "GET",
        "responses": [
            {
                "status": 200,
                "body": {
                    "advanceProducts": [
                        {
                            "productName": "Amortizing Advance",
                            "description": "Whether a financial institution is looking to fund mortgages or investments or cover short-term funding gaps, the Amortizing Advance can be structured to meet specific funding needs and manage unpredictable cash flows",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steepening",
                                "Rising"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$1 or $2M for putable/structured advances",
                            "interestPayments": "First business day of the month",
                            "principalDue": "At maturity",
                            "putable": "Yes or No",
                            "availability": "Same day/next day depending on time of day",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Capped Floater Advance",
                            "description": "Whether facing a rising- or fall-rate environment, the Capped Floater Advance is ideal for managing the balance sheet",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals ",
                            "principalDue": "At maturity",
                            "putable": "Yes or No",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Classic Advance",
                            "description": "Offering maturity options across the curve, the popular Classic Advance serves as the core building block of your asset-liability management strategy.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$1",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "No",
                            "availability": "Same day",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Classic Plus Cap Advance",
                            "description": "Financial institutions seeking long-term protection against rising rates and the potential to decrease interest expense when rates rise will find that the Classic Plus Cap Advance is an ideal funding option.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "One- or three-month intervals",
                            "principalDue": "At maturity",
                            "putable": "No",
                            "availability": "Same day",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Collar Advance",
                            "description": "An important tool for liability-sensitive financial institutions seeking to manage-interest rate risk, the Collar Advance combines an interest-rate cap with an interest-rate floor feature.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Steeping",
                                "Rising"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals ",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Corridor Advance",
                            "description": "Financial institutions exposed to rising interest rates find the Corridor Advance a cost-effective solution to mitigate the risk and manage short-term balance-sheet interest-rate sensitivity. ",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals ",
                            "principalDue": "At maturity",
                            "putable": "Yes or No",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Curve Flattener Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Flat",
                                "Flattening"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals ",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Curve Steepener Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Steeping",
                                "Rising"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals ",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Daily Cash Manager Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "One day",
                            "minimumAmount": "$2M",
                            "interestPayments": "At maturity/next business day",
                            "principalDue": "At maturity/next business day",
                            "putable": "No",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "No"
                        },
                        {
                            "productName": "Discount Note Auction-Floater Advance",
                            "description": "An ideal product for asset-sensitive financial institutions, the Discount Note Auction-Floater Advance can be used to improve liquidity ratios and manage interest-rate risk, while offering the ability to prepay without a fee.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals ",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Next day",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Expander Advance",
                            "description": "Providing the flexibility to reevaluate your funding requirements with the option to double the advance amount at the original interest rate, this advance can be particularly attractive to financial institutions that are uncertain of the cash flows stemming from their mortgage portfolios.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "Yes or No",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Flipper Advance",
                            "description": "Generally used in flat yield curve, the Flipper Advance helps manage interest-rate risk sensitivity while offering the opportunity to convert to a fixed-rate advance.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Flat",
                                "Flattening"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Same day",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Flipper Advance with Guaranteed Flip",
                            "description": "A floating- to fixed-rate advance, the Flipper Advance With Guaranteed Flip is an ideal balance sheet funding tool to enhance net interest margins that can also be used as a hedge against rising interest rates.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Flat",
                                "Flattening"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "No",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "HLB-Option Advance",
                            "description": "The Classic Product",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Flat",
                                "Flattening"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "IDEAL Cash Manager Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "One day",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "No",
                            "availability": "One day",
                            "prepaymentEligible": "No"
                        },
                        {
                            "productName": "Knockout Advance",
                            "description": "Using this advance, financial institutions can save more on their borrowing costs by selling the option to cancel at a predetermined date prior to maturity.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Flat",
                                "Flattening"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Member-Option Advance",
                            "description": "Appealing to asset-sensitive financial institutions and those that are unsure of their liquidity needs, this product offers the ability to lock in funding with the option to prepay without a fee.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Three-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Rollover Cash Management Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "One day",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month",
                            "principalDue": "Flexible",
                            "putable": "No",
                            "availability": "One day",
                            "prepaymentEligible": "No"
                        },
                        {
                            "productName": "Slider Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Floating",
                            "rateOutlook": [
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "At adjustment intervals",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "SOFR-Indexed Advance",
                            "description": "Approachable description designed to match how the Money Desk might describe the product to a caller.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Match funds adjustable-rate assets",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising",
                                "Flat",
                                "Flattening",
                                "Falling"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Annually",
                            "principalDue": "At maturity",
                            "putable": "Yes or No",
                            "availability": "Two-day forward",
                            "prepaymentEligible": "Yes"
                        },
                        {
                            "productName": "Symmetrical Prepayment Advance",
                            "description": "An ideal advance option for financial institutions seeking flexibility and diversification in supporting net income and maximizing earnings.",
                            "fundingGoal": [
                                "Covers short-term funding gaps",
                                "Funds mortgage pipelines",
                                "Manages prepayment risks",
                                "Match funds adjustable-rate assets",
                                "Match funds fixed-rate assets",
                                "Protects against rising rates",
                                "Protects against falling rates",
                                "Reduces funding costs"
                            ],
                            "fundingType": "Fixed",
                            "rateOutlook": [
                                "Steeping",
                                "Rising"
                            ],
                            "termLimitations": "20 years",
                            "minimumAmount": "$2M",
                            "interestPayments": "Second business day of the month or semi-annually",
                            "principalDue": "At maturity",
                            "putable": "Yes",
                            "availability": "Next day",
                            "prepaymentEligible": "Yes"
                        }
                    ]
                },
                "type": "CompareAdvanceDetailsGET"
            }
        ]
    }
];
export const AdvanceDataMocksProvider: Provider = createMocks(examples, ADVANCE_DATA_CONFIG);
