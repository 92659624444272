import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fhlb-widget-fhlb-advances-ng',
  template: '../index.html'
})
export class WidgetFhlbAdvancesNgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
