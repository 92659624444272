import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ItemModel } from '@backbase/core-ang';
import { DomSanitizer, SafeUrl, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'fhlb-widget-fhlb-maps-ng',
  templateUrl: "./../maps-page.html",
  styles: []
})
export class WidgetFhlbMapsNgComponent implements OnInit {
  mapAddress: SafeResourceUrl | undefined;
  constructor(private model: ItemModel, public sanitizer: DomSanitizer) { }

      
  addressURL(){
    return this.mapAddress;
   }

  ngOnInit() {

        this.model.property("address").subscribe(
          (address: any) => {
            address ="https://maps.google.com/maps?q="+address+"&z=15&t=m&hl=en-US&gl=US&output=embed";
            this.mapAddress =  this.sanitizer.bypassSecurityTrustResourceUrl(address);
          });
  }
}
