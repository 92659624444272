import { Component, OnInit } from '@angular/core';
// import { FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'fhlb-widget-fhlb-list-of-advances-options-ng',
  template: './../index.html'
})
export class WidgetFhlbListOfAdvancesOptionsNgComponent {




  constructor() { }


}
