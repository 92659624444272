import { createMocks } from '@backbase/foundation-ang/data-http';
import { Provider } from '@angular/core';
import { FORMS_DATA_CONFIG } from './forms-data.service';
const examples = [
    {
        "urlPattern": "/{version}/forms/documents",
        "method": "GET",
        "responses": []
    },
    {
        "urlPattern": "/{version}/forms/documents",
        "method": "POST",
        "responses": []
    },
    {
        "urlPattern": "/{version}/forms/documents",
        "method": "PUT",
        "responses": []
    },
    {
        "urlPattern": "/{version}/forms/documents",
        "method": "DELETE",
        "responses": [
            {
                "status": 200,
                "body": null,
                "type": "SuccessResponse"
            }
        ]
    },
    {
        "urlPattern": "/{version}/forms/documents/{title}",
        "method": "GET",
        "responses": [
            {
                "status": 200,
                "body": null,
                "type": "any"
            }
        ]
    }
];
export const FormsDataMocksProvider: Provider = createMocks(examples, FORMS_DATA_CONFIG);
