import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fhlb-widget-fhlb-list-of-mpf-direct-options-ng',
  template: './../index.html'
})
export class WidgetFhlbListOfMpfDirectOptionsNgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
