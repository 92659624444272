/**
* The endpoint for a data service can be configured at two levels, depending on the setup of the deployment.
* The API base URL can be configured globally and will be respected by all data services,
* while a service specific configuration can be provided in case more granular control is required. The following examples, when combined, will cause the data service from @backbase/demo-data-http-ang to make its requests to /custom-api/custom-path/*
* Global configuration is exposed through the DataHttpModule from @backbase/foundation-ang/data-http.
* It can be imported into your angular application module to provide the relevant configuration.
* The forRoot helper takes a DataHttpConfig, allowing the configuration of the baseUrl.
* @author -Mindtree
*/

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { Inject, InjectionToken } from "@angular/core";
import { ServiceDataHttpConfig } from "@backbase/foundation-ang/data-http";
import * as Data from "./advance-data.interfaces";
const version = 'v1', normalizeHttpParameter = (accum: NormalizedHttpParameters, [key, value]: [string, string | string[] | undefined | number]) => {
    if (value === undefined) {
        return accum;
    }
    if (typeof value === 'number') {
        return { ...accum, [key]: String(value) };
    }
    return { ...accum, [key]: value };
}, normalizeHttpParameters = (params: HttpParameters = {}): NormalizedHttpParameters => Object.entries(params).reduce(normalizeHttpParameter, {});
export const ADVANCE_DATA_CONFIG = new InjectionToken("ServiceDataHttpConfig");
@Injectable({
    providedIn: 'root'
})
export class AdvanceDataService {
    constructor(private readonly http: HttpClient, 
    @Inject(ADVANCE_DATA_CONFIG)
    private readonly config: ServiceDataHttpConfig) { }
    getAdvancesDetails(params: {
        fundingGoal?: string[];
        fundingType?: string[];
        rateOutlook?: string[];
        putable?: string[];
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.AdvanceDetailsGET>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/advances/details`;
        return this.http.request<Data.AdvanceDetailsGET>('get', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    postAdvancesAddRecord(body: Data.AdvanceDetailsPost, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.AdvanceDetailsResponse>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/advances/add`;
        return this.http.request<Data.AdvanceDetailsResponse>('post', uri, {
            body,
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    putAdvancesUpdateRecord(body: Data.AdvanceDetailsPost, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.AdvanceDetailsResponse>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/advances/update`;
        return this.http.request<Data.AdvanceDetailsResponse>('put', uri, {
            body,
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    deleteAdvancesDeleteRecord(params: {
        advanceNames: string[];
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.AdvanceDetailsResponse>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/advances/delete`;
        return this.http.request<Data.AdvanceDetailsResponse>('delete', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    getAdvancesCompareDetails(params: {
        advanceNames: string[];
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.CompareAdvanceDetailsGET>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/advances/compareDetails`;
        return this.http.request<Data.CompareAdvanceDetailsGET>('get', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
}
interface NormalizedHttpParameters {
    [k: string]: string | string[];
}
interface HttpParameters {
    [k: string]: string | string[] | undefined | number;
}
