import { Component, OnInit } from '@angular/core';
import { WidgetFhlbStrategiesNgService } from '@backbase/widget-fhlb-strategies-ng';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';
import { ItemModel } from '@backbase/core-ang';

@Component({
  selector: 'fhlb-widget-fhlb-more-strategies-ng',
  templateUrl: "./../more-strategies-page.html",
  styles: []
})
export class WidgetFhlbMoreStrategiesNgComponent implements OnInit {
  moreStrategiesList: any;
  moreStrategiesParams: any = {};
  showErrorMessage: any;
  pageName: any;

  constructor(private strategiesDataService: WidgetFhlbStrategiesNgService, private model: ItemModel,private cookieService: CookieService) { }

  ngOnInit() {
    // Get the page name from preference.
    this.model.property("pageName").subscribe(
      (pageName) => {
        this.pageName = pageName;
      });
    Object.assign(this.moreStrategiesParams, { "isListStrategy": "N" });
    Object.assign(this.moreStrategiesParams, { "contentTitle": this.pageName });
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.moreStrategiesList = this.strategiesDataService.getStrategiesList(this.moreStrategiesParams, headers);
    this.showErrorMessage = this.strategiesDataService.currentErrorMessage;
  }

}
