import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'fhlb-search-list',//fhlb-search-list
  templateUrl: "./../search-results.html"
})
export class FhlbankSearchListComponent implements OnInit {
  @Input() searchList: any = [];
  @Input() timeTaken:any;
  @Input() totalResult:any = [];
  @Input() searchFilterParams: any;
  @Input() pagenationParamsDetails: any;
  //@Input() totalSearchCount: any;
  @Output() selectedfiltername = new EventEmitter();
  totalCount: any = 0;
  errorMessage: boolean = false;
  paramsObject: any = {};
  pager: any = {}
  pagedItems: any;
  http: any;

  getPaginationResult(page: number) {
    Object.assign(this.searchFilterParams, { "from": page - 1 });
    Object.assign(this.searchFilterParams, { "suggestion": false });
    this.selectedfiltername.emit(this.searchFilterParams);
    var pageScrollToTop = document.getElementById('search-results');
    if (pageScrollToTop != null) {
      pageScrollToTop.scrollIntoView();
    }
  }

  setPage() {
    let page = this.pagenationParamsDetails.from + 1;
    let pagesize = this.pagenationParamsDetails.size;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pager = this.getPager(this.totalCount, page, pagesize);
  }

  getPager(totalItems: number, currentPage: number, pageSize: number) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);
    let destopsize = 10;
    let iPadsize = 5;
    let mobilesize = 2;
    let desktopStartPage: number, desktopEndPage: number;
    let iPadStartPage: number, iPadEndPage: number;
    let mobileStartPage: number, mobileEndPage: number;
    if (totalPages <= destopsize) {
      desktopStartPage = 1;
      desktopEndPage = totalPages;
    } else {
      if (currentPage <= 5) {
        desktopStartPage = 1;
        desktopEndPage = destopsize;
      } else if (currentPage + 4 >= totalPages) {
        desktopStartPage = totalPages - destopsize + 1;
        desktopEndPage = totalPages;
      } else {
        desktopStartPage = currentPage - 5;
        desktopEndPage = currentPage + 4;
      }
    }

    if (totalPages <= iPadsize) {
      iPadStartPage = 1;
      iPadEndPage = totalPages;
    } else {
      if (currentPage <= 2) {
        iPadStartPage = 1;
        iPadEndPage = iPadsize;
      } else if (currentPage + 2 >= totalPages) {
        iPadStartPage = totalPages - iPadsize + 1;
        iPadEndPage = totalPages;
      } else {
        iPadStartPage = currentPage - 2;
        iPadEndPage = currentPage + 2;
      }
    }
    if (totalPages <= mobilesize) {
      mobileStartPage = 1;
      mobileEndPage = totalPages;
    } else {
      if (currentPage <= 1) {
        mobileStartPage = 1;
        mobileEndPage = mobilesize;
      } else if (currentPage + 1 >= totalPages) {
        mobileStartPage = totalPages - mobilesize + 1;
        mobileEndPage = totalPages;
      } else {
        mobileStartPage = currentPage;
        mobileEndPage = currentPage + 1;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let desktopPages: any[] = [];
    for (let i = desktopStartPage; i <= desktopEndPage; i++) {
      desktopPages.push(i);
    }
    let mobilePages: any[] = [];
    for (let i = mobileStartPage; i <= mobileEndPage; i++) {
      mobilePages.push(i);
    }
    let iPadPages: any[] = [];
    for (let i = iPadStartPage; i <= iPadEndPage; i++) {
      iPadPages.push(i);
    }

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      desktopStartPage: desktopStartPage,
      desktopEndPage: desktopEndPage,
      startIndex: startIndex,
      endIndex: endIndex,
      desktopPages: desktopPages,
      mobilePages: mobilePages,
      iPadPages: iPadPages
    };
  }

  /**
   * Function to display the date and time in formatted 
   * @param effectiveDate 
   */
  getFormatedDate(effectiveDate: any) {
    var dateFormat = require('dateformat');
    var d = new Date(effectiveDate);
    return dateFormat(d, "mmmm dd, yyyy");
  }

  searchQuery(e: { target: { checked: boolean; value: string }; }){
    this.paramsObject = {};
    
    Object.assign(this.paramsObject, { "query": e });
    Object.assign(this.paramsObject, { "suggestion": false });
this.selectedfiltername.emit(this.paramsObject);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchList']) {
      if (this.searchList != null) {
        this.totalCount = this.totalResult.totalHits;
        if (this.searchList.length == 0) {
          this.errorMessage = true;
        } else {
          this.errorMessage = false;
        }
      }
    }
  }

  /**
   * On Selecting the Search Heading, we need to redirect to that particular page.
   * @param SearchName 
   */
  getSearchURL(SearchName: any) {
    // forming the Page URL.
    var SearchURL = window.BB.config.linkRoot + "/" +SearchName ;
    return SearchURL;

  }

  getformattedPath(searchResult:any){
    var breadcrumbPath;
    if(searchResult.path != null)
    {
      breadcrumbPath = searchResult.path;
    }else{
      breadcrumbPath = searchResult.generatedPath;
    }
    if(breadcrumbPath.startsWith("fhlbank-boston")){
      breadcrumbPath = breadcrumbPath.replace("fhlbank-boston", "Home");
    }
    breadcrumbPath= breadcrumbPath.replace(/[/]/g, " > ")
    return breadcrumbPath;
    
  }

  constructor(private cd: ChangeDetectorRef) { }
  ngOnInit() {
    this.timeTaken = Math.fround(this.timeTaken/1000).toFixed(5);
    this.setPage();
  }
}
