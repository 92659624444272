import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule, Router } from '@angular/router';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { environment } from '../environments/environment';
import { WidgetFhlbankAdvancePageNgModule } from '@backbase/widget-fhlbank-advance-page-ng';
import { WidgetFhlbAdvancesNgModule } from '@backbase/widget-fhlb-advances-ng';
import { WidgetFhlbHeaderLoginNgModule } from '@backbase/widget-fhlb-header-login-ng';
import { WidgetFhlbAdvancesFeaturesNgModule } from '@backbase/widget-fhlb-advances-features-ng/';
import { AdvanceDataModule } from '@backbase/advance-data';
import { HttpClientModule } from '@angular/common/http';
import {
  PanelContainerModule,
  ColumnContainerModule,
  DeckContainerModule,
  TabContainerModule,
  ContainersModule
} from '@backbase/universal-ang/containers';
import { LayoutContainerModule } from '@backbase/universal-ang/layouts';
import { SetLocaleWidgetModule } from '@backbase/universal-ang/locale';
import { WidgetFhlbankCompareAdvanceNgModule } from '@backbase/widget-fhlbank-compare-advance-ng';
import { WidgetFhlbAdvancesHeaderNgModule } from '@backbase/widget-fhlb-advances-header-ng';
import { RatesDataModule } from '@backbase/rates-data';
import { WidgetFhlbRatesNgModule } from '@backbase/widget-fhlb-rates-ng';
import { WidgetFhlbListOfAdvancesOptionsNgModule } from '@backbase/widget-fhlb-list-of-advances-options-ng';
import { FhlbUiComponentsLibModule } from '@backbase/fhlb-ui-components-lib';
import { WidgetFhlbStrategiesNgModule } from '@backbase/widget-fhlb-strategies-ng';
import { WidgetFhlbMoreStrategiesNgModule } from '@backbase/widget-fhlb-more-strategies-ng';
import { WidgetFhlbVideoNgModule } from '@backbase/widget-fhlb-video-ng';
import { WidgetFhlbNewsNgModule } from '@backbase/widget-fhlb-news-ng';
import { SiNewsEventsDataModule } from '@backbase/si-news-events-data';
import { WidgetFhlbEventsNgModule } from '@backbase/widget-fhlb-events-ng';
import { WidgetFhlbMapsNgModule } from '@backbase/widget-fhlb-maps-ng';
import { SearchDataModule } from '@backbase/search-data';
import { WidgetFhlbSearchNgModule } from '@backbase/widget-fhlb-search-ng';
import { WidgetFhlbSearchHeaderNgService } from './widget-fhlb-search-header-ng.service';
import { FhlbankSearchHeaaderQueryComponent } from './fhlbank-search-header-query.component';
import { WidgetFhlbListOfMpfOptionsNgModule } from '@backbase/widget-fhlb-list-of-mpf-options-ng';

import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { Ng2CompleterModule } from "ng2-completer";
import { WidgetFhlbRatesPageNgModule } from '@backbase/widget-fhlb-rates-page-ng';
import { WidgetFhlbFormsPageNgModule } from '@backbase/widget-fhlb-forms-page-ng';
import { WidgetFhlbHistoricalRatesNgModule } from '@backbase/widget-fhlb-historical-rates-ng';
import { FormsDataModule } from '@backbase/forms-data';
import { CookieService } from 'ngx-cookie-service';
import { WidgetFhlbListOfMpfDirectOptionsNgModule } from '@backbase/widget-fhlb-list-of-mpf-direct-options-ng';
import { SecDataModule } from '@backbase/sec-data';
import { WidgetFhlbInvestorSecFillingNgModule } from '@backbase/widget-fhlb-investor-sec-filling-ng';

@NgModule({
  declarations: [
    AppComponent, FhlbankSearchHeaaderQueryComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    NgbModule,
    FormsModule,
    Ng2CompleterModule,
    BackbaseCoreModule,
    WidgetFhlbankAdvancePageNgModule,
    WidgetFhlbAdvancesNgModule,
    WidgetFhlbHeaderLoginNgModule,
    RouterModule.forRoot([], { initialNavigation: false, useHash: true }),
    HttpClientModule,
    WidgetFhlbAdvancesFeaturesNgModule,
    AdvanceDataModule,
    PanelContainerModule,
    ColumnContainerModule,
    DeckContainerModule,
    TabContainerModule,
    ContainersModule,
    SetLocaleWidgetModule,
    LayoutContainerModule,
    WidgetFhlbankCompareAdvanceNgModule,
    WidgetFhlbAdvancesHeaderNgModule,
    RatesDataModule,
    WidgetFhlbRatesNgModule,
    WidgetFhlbListOfAdvancesOptionsNgModule,
    FhlbUiComponentsLibModule,
    WidgetFhlbStrategiesNgModule,
    WidgetFhlbMoreStrategiesNgModule,
    WidgetFhlbVideoNgModule,
    WidgetFhlbNewsNgModule,
    SiNewsEventsDataModule,
    WidgetFhlbEventsNgModule,
    WidgetFhlbMapsNgModule,
    SearchDataModule,
    WidgetFhlbSearchNgModule,
    WidgetFhlbRatesPageNgModule,
    WidgetFhlbFormsPageNgModule,
    WidgetFhlbHistoricalRatesNgModule,
    FormsDataModule,
    WidgetFhlbListOfMpfOptionsNgModule,
    WidgetFhlbListOfMpfDirectOptionsNgModule,
    SecDataModule,
    WidgetFhlbInvestorSecFillingNgModule
  ],
  exports: [
    RouterModule
  ],
  providers: [...environment.mockProviders, WidgetFhlbSearchHeaderNgService, CookieService ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router) {

  }
}
