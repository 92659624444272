import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { WidgetFhlbVideoNgComponent } from './widget-fhlb-video-ng.component';

@NgModule({
  imports: [
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbVideoNgComponent }
    })
  ],
  declarations: [WidgetFhlbVideoNgComponent]
})
export class WidgetFhlbVideoNgModule { }
