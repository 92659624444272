import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

 
 import {FormsDataService, FormsDocumentResponse,FormsDocumentData} from '@backbase/forms-data';

@Injectable()
export class WidgetFhlbFormsPageNgService {

  errorMessage = new Subject<boolean>();
  currentErrorMessage = this.errorMessage.asObservable();

  totalCount = new Subject<any>();
  currentTotalCount = this.totalCount.asObservable();

  constructor(private readonly data: FormsDataService) { }

  narrowList = require('./forms-narrow-list.json');
  formsNarrowList=this.narrowList.narrowList;

  /**
   * This function will return the Forms for that Product when we pass the product name as a parameter.
   * @param FormsParams 
   */
  getFormsList(FormsParams: any, headers: HttpHeaders): Observable<any> {
    return this.data.getFormsDocuments(FormsParams, headers).pipe(map((response: HttpResponse<FormsDocumentResponse>): any => {
      this.errorMessage.next(false);
      this.totalCount.next(response.headers.get('X-TOTAL-COUNT'));
      return response;
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
      this.totalCount.next(0);
        return of();
    }));
  }

  
  getDocumentByTitle( params:any, headers: HttpHeaders,fileType:any ): Observable<any>  {
    return this.data.getFormsDocumentsRecordByTitleUrl(params, headers).pipe(map((response: HttpResponse<any>): any => {
      this.errorMessage.next(false);
      return [response, fileType];
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
        return of();
    }));
  }

}

