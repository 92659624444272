import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { IMyDpOptions, IMyDate, IMyDateModel, IMyInputFieldChanged, IMyInputFocusBlur, IMyCalendarViewChanged } from 'mydatepicker';
import { WidgetFhlbHistoricalRatesNgService } from './widget-fhlb-historical-rates-ng.service';
import { InputDatepickerModule, DateRangeModel } from '@backbase/foundation-ang/ui';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'fhlb-historical-rates-calendar',
  templateUrl: "./../historical-rates-calendar.html",
  styles: []
})
export class FhlbankHistoricalRatesCalendarComponent implements OnInit {
  @Input() downloadAPIDown: any;
  @Input() httpResponseStatus: any;
  @Output() rateDownloadAPI = new EventEmitter();
  validDate: Boolean = true;
  disableButton: Boolean = true;
  selectedDate!: any;
  lastenableDate: IMyDate;
  params: any;
  errorContent: any = "";
  invalidDateError: any = "Historical rates data is available for business days from 01/01/1991 through yesterday. Please enter a new numeric date in MM/DD/YYYY format";
  numericalError: any = "Please enter numeric characters only";

  myDatePickerOptions: IMyDpOptions = {
    todayBtnTxt: 'Today',
    showTodayBtn: false,
    dayLabels: { su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S' },
    dateFormat: 'mm/dd/yyyy',
    satHighlight: true,
    firstDayOfWeek: 'su',
    sunHighlight: true,
    inline: false,
    ariaLabelDay:"Selected Day ",
    ariaLabelPrevMonth:"Previous Month",
    ariaLabelNextMonth:"Next Month",
    ariaLabelPrevYear:"Previous Year",
    ariaLabelNextYear:"Next Year",
    showClearDateBtn: false,
    disableWeekends: true,
    disableUntil: { year: 1990, month: 12, day: 31 }
  };


  ngOnChanges(changes: SimpleChanges) {


  }

  /**
  * Function to get the result based on Selected Terms
  * @param selectedTerm 
  */
  onSelectedRatesDownload(downloadFormat: any) {
    this.params = {};
    Object.assign(this.params, { "businessDate": this.selectedDate.year + "-" + ("0" + this.selectedDate.month).slice(-2) + "-" + ("0" + this.selectedDate.day).slice(-2) });
    Object.assign(this.params, { "isHistoricalPage": "Y" });
    Object.assign(this.params, { "fileType": downloadFormat });
    this.rateDownloadAPI.emit(this.params);
  }

  onDateChanged(event: IMyDateModel) {
    if (event.formatted == null || event.formatted == "") {
      this.selectedDate = "";

    } else {
      this.selectedDate = event.date;
      this.validateBusinessDate();
    }
  }
  validateBusinessDate() {
    let params: any = {};
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers = new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    let businessDate = this.selectedDate.year + "-" + ("0" + this.selectedDate.month).slice(-2) + "-" + ("0" + this.selectedDate.day).slice(-2);
    Object.assign(params, { "businessDate": businessDate });
    if (this.validatePreviousOrFutureDate(businessDate)) {
      this.ratesService.getBusinessDayValidation(params, headers).subscribe(
        response => {
          this.errorContent = this.invalidDateError;
          if(Object.keys(response).length != 0)
          {
          this.validDate = response.businessday.isBusinessDay;
          this.disableButton = !response.businessday.isBusinessDay;
          }else{
            this.validDate = false;
          this.disableButton = true;
          }
          this.cd.detectChanges();
        }, error => {
          this.enableErrorBlock("The historical rates download is temporarily unavailable. Please try again later.", false, true);
          this.cd.detectChanges();
        });
    } else {
      this.enableErrorBlock(this.invalidDateError, false, true);
    }
  }

  validatePreviousOrFutureDate(dateParams: any) {
    var previousDate: any = this.myDatePickerOptions.disableUntil;
    var futureDate: any = this.myDatePickerOptions.disableSince;
    var selectedBusinessDate = dateParams.split("-");

    var formattedPreviousDate = new Date(previousDate.year, previousDate.month - 1, previousDate.day).getTime();
    var formattedFutureDate = new Date(futureDate.year, futureDate.month - 1, futureDate.day).getTime();
    var formattedSelectedDate = new Date(selectedBusinessDate[0], selectedBusinessDate[1] - 1, selectedBusinessDate[2]).getTime();
    if (formattedPreviousDate - formattedSelectedDate < 0) {
      if (formattedSelectedDate - formattedFutureDate < 0) {
        return true;
      } else {
        return false;
      }

    } else {
      return false
    }
  }


  onInputFieldChanged(event: IMyInputFieldChanged) {
    if (!event.valid) {
      if (event.value == null || event.value == "") {
        this.enableErrorBlock("", true, true);
      } else {
        var validNumbers = /^\d+$/.test(event.value);
        if (validNumbers) {
          this.enableErrorBlock("", true, true);
          if (event.value.length == 8) {
            this.selectedDate = {
              year: parseInt(event.value.slice(4, 8)),
              month: parseInt(event.value.slice(0, 2)),
              day: parseInt(event.value.slice(2, 4))
            }
            this.validateBusinessDate();
          }
        } else {
          if (event.value.length == 10) {
            if ((/^\d{2}\/\d{2}\/\d{4}$/).test(event.value)) {
              this.selectedDate = {
                year: parseInt(event.value.slice(6, 10)),
                month: parseInt(event.value.slice(0, 2)),
                day: parseInt(event.value.slice(3, 5))
              }
              this.enableErrorBlock("", true, false);
              this.validateBusinessDate();
            } else {
              this.enableErrorBlock(this.numericalError, false, true);
            }
          }
          else {
            var numberwithSlash = /^[0-9\/]+$/.test(event.value);
            if (numberwithSlash) {
              var dateInput = event.value;
              var validInput = true;
              if (dateInput.length <= 10) {
                for (var i = 0; i < dateInput.length; i++) {
                  if (i == 2 || i == 5) {
                    var validCharacter = /^[\/]+$/.test(dateInput[i]);
                    if (!validCharacter) {
                      validInput = false;
                    }
                  } else {
                    var validCharacter = /^\d+$/.test(dateInput[i]);
                    if (!validCharacter) {
                      validInput = false;
                    }
                  }
                }
              }else{
                validInput = false;
              }
              if (!validInput) {
                this.enableErrorBlock(this.invalidDateError, false, true);
              } else {
                this.enableErrorBlock("", true, true);
              }
            } else {
              this.enableErrorBlock(this.numericalError, false, true);
            }
          }
        }
      }
    }
  }

  enableErrorBlock(errorContent: any, ValidateDate: any, DisableButton: any) {
    this.errorContent = errorContent;
    this.validDate = ValidateDate;
    this.disableButton = DisableButton;
  }
  onCalendarViewChanged(event: IMyCalendarViewChanged) {
  }

  onCalendarToggle(event: number): void {
  }

  onInputFocusBlur(event: IMyInputFocusBlur): void {
  }

  errorMessageHidden() {
    setTimeout(() => {
      this.validDate = true;
      this.cd.markForCheck();
    }, 5000);

  }

  constructor(private ratesService: WidgetFhlbHistoricalRatesNgService, private cookieService: CookieService, private cd: ChangeDetectorRef) {
    let lastSelectableDate: Date = new Date(Date.now());
    this.lastenableDate = {
      year: lastSelectableDate.getFullYear(),
      month: lastSelectableDate.getMonth() + 1,
      day: lastSelectableDate.getDate()
    };
    this.myDatePickerOptions.disableSince = this.lastenableDate;
  }

  ngOnInit() {
  }


}
