import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, AfterViewChecked } from '@angular/core';


@Component({
  selector: 'fhlb-rates-download',
  templateUrl: "./../rates-download.html",
  styles: []
})
export class FhlbankRatesDownloadComponent implements OnInit {
@Input() downloadAPIDown: any;
@Input() httpResponseStatus: any;
  @Output() rateDownloadAPI = new EventEmitter();
  selectedRateDetails: any;
  currentRates:any;
  params:any;

getHistroricalRatesURL(){
  var historicalURL = window.BB.config.linkRoot + "/" + window.BB.config.portalName + "/historical-rates";
     return historicalURL;

}

  /**
   * Function to get the result based on Selected Terms
   * @param selectedTerm 
   */
  onSelectedRatesDownload(downloadFormat: any) {
    if(downloadFormat =="historical"){
      var historicalURL = window.BB.config.linkRoot + "/" + window.BB.config.portalName + "/historical-rates";
      window.location.href = historicalURL;
    }else{
    this.params={};
    Object.assign(this.params, { "fileType": downloadFormat});
    this.rateDownloadAPI.emit(this.params);
  }
}


  ngOnChanges(changes: SimpleChanges) {
    if (changes['httpResponseStatus']) {
    }

  }

  constructor() { 

  }
  
  ngOnInit() {
  window.scrollTo(0, 0);
  }
}
