import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AdvanceDetails, WidgetFhlbAdvancesFeaturesNgService } from './widget-fhlb-advances-features-ng.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fhlb-advance-features',
  templateUrl: "./../advance-details.html"
})
export class FhlbankAdvanceFeaturesComponent implements OnInit {
  @Input() advanceFeatureList: any;
  selectedAdvance: any;
  rateOutLookImages: any = {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['advanceFeatureList']) {
      if (this.advanceFeatureList != null) {
        // Storing the value to local instance to get it display.
        this.selectedAdvance = this.advanceFeatureList;
      }

    }
  }

  constructor(private advanceService: WidgetFhlbAdvancesFeaturesNgService) {
    // to get the images from Content Repository.
    this.rateOutLookImages = {
      "Steepening": "/api/contentservices/api/contentstream/fhlbank-boston/RateOutlook_PlaceholderSteepening.svg",
      "Rising": "/api/contentservices/api/contentstream/fhlbank-boston/RateOutlook_PlaceholderRising.svg",
      "Flat": "/api/contentservices/api/contentstream/fhlbank-boston/RateOutlook_PlaceholderFlat.svg",
      "Flattening": "/api/contentservices/api/contentstream/fhlbank-boston/RateOutlook_PlaceholderFlattening.svg",
      "Falling": "/api/contentservices/api/contentstream/fhlbank-boston/RateOutlook_PlaceholderFalling.svg"
    }
  }
  ngOnInit() {

  }

}
