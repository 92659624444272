import { Component, OnInit, Input, SimpleChanges, ElementRef, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { WidgetFhlbankAdvancePageNgService, AdvanceDetails } from './widget-fhlbank-advance-page-ng.service';
import { Router } from '@angular/router';
import { ValueTransformer } from '@angular/compiler/src/util';



@Component({
  selector: 'fhlb-advance-list',
  templateUrl: "./../advance-results.html"
})
export class FhlbankAdvanceListComponent implements OnInit {
  @Input() advance: Array<AdvanceDetails> = [];
  @ViewChildren("compareCheckboxes")
  compareCheckboxes!: QueryList<ElementRef>;
  count = 0;  // To show the compare button with count
  comparenow = "COMPARE"; // Chaning add to compare link to compare now on selecting
  compareList: any = []; // Selected compare List
  errorMessage: boolean = false;
  selectedProduct: any;
  comparebtn: boolean = true;
  showErrorMessage:any="";
  productSelectMessage: any = "";


  checkboxErrorMessage() {
    setTimeout(() => {
      this.productSelectMessage = ""; // Here, value is updated
      this.cd.markForCheck();
    }, 2000);

  }

  /**
   * Action on selecting or deselecting compare checkbox
   * @param e 
   */

  compareCheckboxClicked(e: { target: { checked: boolean; value: string }; }, advanceList: any) {
    // [disabled]="!advanceList.checked && count === 4"
    /*if (e.target.checked && this.count === 4) {
      e.target.checked = false;
      advanceList.checked = false;
      this.productSelectMessage = e.target.value;
      this.checkboxErrorMessage();
    } else {*/
      var index1 = this.compareList.indexOf("compare now");
      if (index1 !== -1) this.compareList.splice(index1, 1);
      if (e.target.checked) {   // Action if the checkbox is selected
        this.count = this.count + 1;
        this.compareList.push(e.target.value);
      } else {  // Action if the checkbox is de-selected
        this.count = this.count - 1;
        var index = this.compareList.indexOf(e.target.value);
        if (index !== -1) {
          this.compareList.splice(index, 1);
        }
      }
      // Enable based on no of checkbox selected.
      if (this.count <= 1) {
        this.comparebtn = true;
      } else {
        this.comparebtn = false;
      }
      //Changing the text content on select
      this.comparenow = "COMPARE (" + this.count + ")";
    }
  //}

  /**
   * Rediecting to Compare now page
   */
  redirectToComparePage(buttonClicked:any) {
    // storing the selected compare list in local storage.
    this.showErrorMessage="";
    if(this.count >4){
      this.showErrorMessage=buttonClicked;

    }else{
      localStorage.setItem("compareList", JSON.stringify(this.compareList));
    var comareAdvanceURL = window.BB.config.linkRoot + "/" + window.BB.config.portalName + "/compare-advance";
    window.location.href = comareAdvanceURL;
    }
    
  }
  ngOnChanges(changes: SimpleChanges) {
    this.count = 0;
    this.comparebtn = true;
    this.compareList = [];
    if (changes['advance']) {
      if (this.advance != null) {
        if (this.advance.length == 0) {
          this.errorMessage = true;
        } else {
          this.errorMessage = false;
          /*this.compareCheckboxes.forEach((element) => {
            element.nativeElement.checked = false;
          });*/
        }
      }
    }
  }

  /**
   * Redirecting to Advance Detail Page.
   * @param advanceName 
   */
  getAdvanceURL(advanceName: any) {
    var advanceURL = window.BB.config.linkRoot + "/" + window.BB.config.portalName + "/" + advanceName.productName.toLowerCase().replace(/[^a-zA-Z-._0-9 ]/g, "").replace(/[ ]/g,"-").replace(/--+/g, '-');
    //window.location.href = advanceURL;
    return advanceURL;
  }

  constructor(private cd: ChangeDetectorRef) { }
  ngOnInit() {
    // Removing Compare list for localstorage on landing to Advance List page.
    localStorage.removeItem("compareList");
  }
}
