import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { WidgetFhlbAdvancesHeaderNgComponent } from './widget-fhlb-advances-header-ng.component';
import { FhlbankAdvanceHeaderComponent } from './fhlb-advance-header.component';
import { FhlbUiComponentsLibModule } from '@backbase/fhlb-ui-components-lib';

@NgModule({
  imports: [
    CommonModule,
    FhlbUiComponentsLibModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbAdvancesHeaderNgComponent }
    })
  ],
  declarations: [WidgetFhlbAdvancesHeaderNgComponent, FhlbankAdvanceHeaderComponent]
})
export class WidgetFhlbAdvancesHeaderNgModule { }
