import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { truncate } from 'fs';


@Component({
  selector: 'fhlb-strategies-list',
  templateUrl: "./../strategies-results.html"
})
export class FhlbankStrategiesListComponent implements OnInit {
  @Input() strategiesList: any = [];
  @Input() strategiesFilterParams: any;
  @Input() pagenationParamsDetails: any;
  @Input() totalStrategiesCount: any;
  @Output() selectedfiltername = new EventEmitter();
  totalCount: any = 0;
  errorMessage: boolean = false;
  strategiesImages: any = {};
  pager: any = {}
  pagedItems: any;
  http: any;

  getPaginationResult(page: number) {
    Object.assign(this.strategiesFilterParams, { "from": page - 1 });
    this.selectedfiltername.emit(this.strategiesFilterParams);
    var pageScrollToTop = document.getElementById('strategies-results');
    if (pageScrollToTop != null) {
      pageScrollToTop.scrollIntoView();
    }
  }

  setPage() {
    let page = this.pagenationParamsDetails.from + 1;
    let pagesize = this.pagenationParamsDetails.size;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pager = this.getPager(this.totalCount, page, pagesize);
  }

  isDisabled(currentValue:any,totalValue:any){
    if(currentValue === totalValue){
      return true;
    }else{
      return false;
    }

    
  }
  getPager(totalItems: number, currentPage: number, pageSize: number) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);
    let destopsize = 10;
    let iPadsize = 5;
    let mobilesize = 2;
    let desktopStartPage: number, desktopEndPage: number;
    let iPadStartPage: number, iPadEndPage: number;
    let mobileStartPage: number, mobileEndPage: number;
    if (totalPages <= destopsize) {
      desktopStartPage = 1;
      desktopEndPage = totalPages;
    } else {
      if (currentPage <= 5) {
        desktopStartPage = 1;
        desktopEndPage = destopsize;
      } else if (currentPage + 4 >= totalPages) {
        desktopStartPage = totalPages - destopsize + 1;
        desktopEndPage = totalPages;
      } else {
        desktopStartPage = currentPage - 5;
        desktopEndPage = currentPage + 4;
      }
    }

    if (totalPages <= iPadsize) {
      iPadStartPage = 1;
      iPadEndPage = totalPages;
    } else {
      if (currentPage <= 2) {
        iPadStartPage = 1;
        iPadEndPage = iPadsize;
      } else if (currentPage + 2 >= totalPages) {
        iPadStartPage = totalPages - iPadsize + 1;
        iPadEndPage = totalPages;
      } else {
        iPadStartPage = currentPage - 2;
        iPadEndPage = currentPage + 2;
      }
    }
    if (totalPages <= mobilesize) {
      mobileStartPage = 1;
      mobileEndPage = totalPages;
    } else {
      if (currentPage <= 1) {
        mobileStartPage = 1;
        mobileEndPage = mobilesize;
      } else if (currentPage + 1 >= totalPages) {
        mobileStartPage = totalPages - mobilesize + 1;
        mobileEndPage = totalPages;
      } else {
        mobileStartPage = currentPage;
        mobileEndPage = currentPage + 1;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let desktopPages: any[] = [];
    for (let i = desktopStartPage; i <= desktopEndPage; i++) {
      desktopPages.push(i);
    }
    let mobilePages: any[] = [];
    for (let i = mobileStartPage; i <= mobileEndPage; i++) {
      mobilePages.push(i);
    }
    let iPadPages: any[] = [];
    for (let i = iPadStartPage; i <= iPadEndPage; i++) {
      iPadPages.push(i);
    }

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      desktopStartPage: desktopStartPage,
      desktopEndPage: desktopEndPage,
      startIndex: startIndex,
      endIndex: endIndex,
      desktopPages: desktopPages,
      mobilePages: mobilePages,
      iPadPages: iPadPages
    };
  }

  /**
   * Function to display the date and time in formatted 
   * @param effectiveDate 
   */
  getFormatedDate(effectiveDate: any) {
    var dateFormat = require('dateformat');
    var d = new Date(effectiveDate);
    return dateFormat(d, "mmmm dd, yyyy");
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['strategiesList']) {
      if (this.strategiesList != null) {
        this.totalCount = this.totalStrategiesCount.get('X-TOTAL-COUNT');
        if (this.strategiesList.length == 0) {
          this.errorMessage = true;
        } else {
          this.errorMessage = false;
        }
      }
    }
  }

  /**
   * On Selecting the Strategies Heading, we need to redirect to that particular page.
   * @param StrategiesName 
   */
  getStrategiesURL(StrategiesName: any) {
    // forming the Page URL.
    var StrategyURL = window.BB.config.linkRoot + "/" + window.BB.config.portalName + "/" + StrategiesName.contentTitle.toLowerCase().replace(/[^a-zA-Z-._0-9 ]/g, "").replace(/[ ]/g, "-").replace(/--+/g, '-');
    window.location.href = StrategyURL;
    //window.open(StrategyURL, '_blank');

  }

  constructor(private cd: ChangeDetectorRef) { }
  ngOnInit() {
    this.setPage();
    //To get the images from Content Repository. 
    this.strategiesImages = {
      "Presentation": "/api/contentservices/api/contentstream/fhlbank-boston/icon_presentation.svg",
      "Article": "/api/contentservices/api/contentstream/fhlbank-boston/icon_article.svg",
      "Infographic": "/api/contentservices/api/contentstream/fhlbank-boston/	icon_infographic.svg",
      "Video": "/api/contentservices/api/contentstream/fhlbank-boston/icon_video.svg",
      "Webinar": "/api/contentservices/api/contentstream/fhlbank-boston/icon_webinar.svg"
    }

  }
}
