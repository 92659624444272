import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

 import {SiNewsEventsDataService, StrategiesContentDetailsGET} from '@backbase/si-news-events-data';

@Injectable()
export class WidgetFhlbEventsNgService {

  errorMessage = new Subject<boolean>();
  currentErrorMessage = this.errorMessage.asObservable();

  constructor(private readonly data: SiNewsEventsDataService) { }

  /**
   * This function will return the Events for that Product when we pass the product name as a parameter.
   * @param EventsParams 
   */
  getEventsList(EventsParams: any, headers: HttpHeaders): Observable<any> {
    return this.data.getNewsDetails(EventsParams, headers).pipe(map((response: HttpResponse<StrategiesContentDetailsGET>): any => {
      this.errorMessage.next(false);
      return response
      ? response : [];
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
        return of();
    }));
  }
}

