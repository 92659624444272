import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

 import {SiNewsEventsDataService, StrategiesContentDetailsGET} from '@backbase/si-news-events-data';

@Injectable()
export class WidgetFhlbNewsNgService {

  errorMessage = new Subject<boolean>();
  currentErrorMessage = this.errorMessage.asObservable();

  
  
  totalCount = new Subject<any>();
  currentTotalCount = this.totalCount.asObservable();

  constructor(private readonly data: SiNewsEventsDataService) { }

  narrowList = require('./strategies-narrow-list.json');
  newsNarrowList=this.narrowList.narrowList;

  /**
   * This function will return the News for that Product when we pass the product name as a parameter.
   * @param NewsParams 
   */
  getNewsList(NewsParams: any, headers: HttpHeaders): Observable<any> {
    return this.data.getNewsDetails(NewsParams, headers).pipe(map((response: HttpResponse<StrategiesContentDetailsGET>): any => {
      this.errorMessage.next(false);
      this.totalCount.next(response.headers.get('X-TOTAL-COUNT'));
      return response
      ? response : [];
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
      this.totalCount.next(0);
        return of();
    }));
  }
}

