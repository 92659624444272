import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { ItemModel } from '@backbase/core-ang';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'fhlb-widget-fhlb-video-ng',
  template: `
  <div class="si-video-wrapper">
  <div class="video-embed-container  [vimeo, widescreen]">
  <iframe [src]='videoDisplayURL()' frameborder="0" allowfullscreen="true" title="{{title}}"
  webkitallowfullscreen="true" mozallowfullscreen="true">
</iframe>
</div>
  </div>
  `,
  styles: []
})
export class WidgetFhlbVideoNgComponent implements OnInit, AfterViewChecked {
  videoURL: SafeResourceUrl | undefined;
  title: any;

  constructor(private model: ItemModel, public sanitizer: DomSanitizer) {
  }

  videoDisplayURL() {
    return this.videoURL;
  }

  public ngAfterViewChecked(): void {

    /* need _canScrollDown because it triggers even if you enter text in the textarea */
    if ($(".si-video-wrapper").length > 0) {
      $(".si-video-mapping > div")
        .children()
        .appendTo(".si-video-editable-templates");
    }
    if ($(".si-video-wrapper").length > 0) {
      $(".si-video-editable-templates > div")
        .children()
        .appendTo(".si-video-wrapper");
    }
    var videoWrapper = document.getElementsByClassName("si-video-wrapper");
    if (videoWrapper != null) {
      window.scrollTo(0, 0);
    }
  }
  ngOnInit() {
    this.model.property("title").subscribe(
      (title: any) => {
        this.title = title;
        this.model.property("videosource").subscribe(
          (videosource: any) => {
            this.model.property("videourl").subscribe(
              (url: any) => {
                let videourl = "";
                if (videosource == "YouTube") {
                  videourl = "https://www.youtube.com/embed/" + url;
                } else {
                  videourl = "https://player.vimeo.com/video/" + url;
                }
                this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(videourl);
              });
          });
      });
  }
}
