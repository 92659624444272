import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {
  AdvanceDetailsGET,
  AdvanceDetails as AdvanceDataItem,
  AdvanceDataService,
} from '@backbase/advance-data';

export interface AdvanceDetails {
  productName: string;
  description?: string;
  fundingGoal?: Array<string>;
  fundingType?: string;
  termLimitations?: string;
  minimumAmount?: string;
  rateOutlook?: Array<string>;
  availability?: string;
  prepaymentEligible?: string;
  putable?: string;
  interestPayments?: string;
  principalDue?: string;
}


@Injectable()
export class WidgetFhlbankCompareAdvanceNgService {
  errorMessage = new Subject<boolean>();
  currentErrorMessage = this.errorMessage.asObservable();

  constructor(private readonly data: AdvanceDataService) { }

  /**
   * Function to get the products for comparing.
   * @param compareParams 
   */
  getCompareList(compareParams: any, headers: HttpHeaders): Observable<any> {
    return this.data.getAdvancesCompareDetails(compareParams, headers).pipe(map((response: HttpResponse<AdvanceDetailsGET>): any => {
      this.errorMessage.next(false);
      return response.body
        ? response.body.advanceProducts : [];
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
      return of();
    }));;
  }
}

