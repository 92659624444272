import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FhlbLoaderModule } from './fhlb-loader/fhlb-loader.module';
import { FhlbErrorMessageModule } from './fhlb-error-message/fhlb-error-message.module';

@NgModule({
  imports: [
    CommonModule,
    FhlbLoaderModule,
    FhlbErrorMessageModule
  ],
  declarations: [],
  exports: [FhlbLoaderModule, FhlbErrorMessageModule]
})
export class FhlbUiComponentsLibModule { }
