import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { WidgetFhlbFormsPageNgService } from './widget-fhlb-forms-page-ng.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'fhlb-forms-list',
  templateUrl: "./../forms-results.html"
})
export class FhlbankFormsListComponent implements OnInit {
  @Input() formsList: any = [];
  @Input() formsFilterParams: any;
  @Input() pagenationParamsDetails: any;
  @Input() totalFormsCount: any;
  @Output() selectedfiltername = new EventEmitter();
  totalCount: any = 0;
  downloadPath: any;
  errorMessage: boolean = false;
  pager: any = {}
  pagedItems: any;
  http: any;
  imageBlobUrl: any;


  getPaginationResult(page: number) {
    Object.assign(this.formsFilterParams, { "from": page - 1 });
    this.selectedfiltername.emit(this.formsFilterParams);
    var pageScrollToTop = document.getElementById('forms-results');
    if (pageScrollToTop != null) {
      pageScrollToTop.scrollIntoView();
    }
  }

  setPage() {
    let page = this.pagenationParamsDetails.from + 1;
    let pagesize = this.pagenationParamsDetails.size;
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pager = this.getPager(this.totalCount, page, pagesize);
  }

  getPager(totalItems: number, currentPage: number, pageSize: number) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);
    let destopsize = 10;
    let iPadsize = 5;
    let mobilesize = 2;
    let desktopStartPage: number, desktopEndPage: number;
    let iPadStartPage: number, iPadEndPage: number;
    let mobileStartPage: number, mobileEndPage: number;
    if (totalPages <= destopsize) {
      desktopStartPage = 1;
      desktopEndPage = totalPages;
    } else {
      if (currentPage <= 5) {
        desktopStartPage = 1;
        desktopEndPage = destopsize;
      } else if (currentPage + 4 >= totalPages) {
        desktopStartPage = totalPages - destopsize + 1;
        desktopEndPage = totalPages;
      } else {
        desktopStartPage = currentPage - 5;
        desktopEndPage = currentPage + 4;
      }
    }

    if (totalPages <= iPadsize) {
      iPadStartPage = 1;
      iPadEndPage = totalPages;
    } else {
      if (currentPage <= 2) {
        iPadStartPage = 1;
        iPadEndPage = iPadsize;
      } else if (currentPage + 2 >= totalPages) {
        iPadStartPage = totalPages - iPadsize + 1;
        iPadEndPage = totalPages;
      } else {
        iPadStartPage = currentPage - 2;
        iPadEndPage = currentPage + 2;
      }
    }
    if (totalPages <= mobilesize) {
      mobileStartPage = 1;
      mobileEndPage = totalPages;
    } else {
      if (currentPage <= 1) {
        mobileStartPage = 1;
        mobileEndPage = mobilesize;
      } else if (currentPage + 1 >= totalPages) {
        mobileStartPage = totalPages - mobilesize + 1;
        mobileEndPage = totalPages;
      } else {
        mobileStartPage = currentPage;
        mobileEndPage = currentPage + 1;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let desktopPages: any[] = [];
    for (let i = desktopStartPage; i <= desktopEndPage; i++) {
      desktopPages.push(i);
    }
    let mobilePages: any[] = [];
    for (let i = mobileStartPage; i <= mobileEndPage; i++) {
      mobilePages.push(i);
    }
    let iPadPages: any[] = [];
    for (let i = iPadStartPage; i <= iPadEndPage; i++) {
      iPadPages.push(i);
    }

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      desktopStartPage: desktopStartPage,
      desktopEndPage: desktopEndPage,
      startIndex: startIndex,
      endIndex: endIndex,
      desktopPages: desktopPages,
      mobilePages: mobilePages,
      iPadPages: iPadPages
    };
  }

  /**
   * Function to display the date and time in formatted 
   * @param effectiveDate 
   */
  getFormatedDate(effectiveDate: any) {
    var dateFormat = require('dateformat');
    var d = new Date(effectiveDate);
    return dateFormat(d, "mmmm dd, yyyy");
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['formsList']) {
      if (this.formsList != null) {
        this.totalCount = this.totalFormsCount.get('X-TOTAL-COUNT');
        if (this.formsList.length == 0) {
          this.errorMessage = true;
        } else {
          this.errorMessage = false;
        }
      }
    }
  }

  /**
   * On Selecting the formsName Heading, we need to redirect to that particular page.
   * @param formsName 
   */
  getFormsURL(formsName: any) {
    return formsName.path
  }

  downloadFile(title: any, fileType: any) {
    let params: any = {};
    params.title = title;
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers = new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.downloadPath = this.formsDataService.getDocumentByTitle( params, headers, fileType).subscribe(
      (response: any) => {
        var url = window.URL.createObjectURL(response[0].body);
        var filename = response[0].headers.get("Content-Disposition").split("filename=")[1];
        // Default Terms Year from Preference to get the initial API.
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response[0].body,filename);
        }
        else {
          if (response[1] === "application/pdf") {
            //window.location.href = this.downloadPath;
            window.open(url, '_blank');
          } else {

            var a: any = document.createElement('A');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }
      },
      err => {
      });
  }

  constructor(private cd: ChangeDetectorRef, private formsDataService: WidgetFhlbFormsPageNgService, private cookieService: CookieService) { }
  ngOnInit() {
    this.setPage();
  }
}
