import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { Inject, InjectionToken } from "@angular/core";
import { ServiceDataHttpConfig } from "@backbase/foundation-ang/data-http";
import * as Data from "./forms-data.interfaces";
const version = 'v1', normalizeHttpParameter = (accum: NormalizedHttpParameters, [key, value]: [string, string | string[] | undefined | number]) => {
    if (value === undefined) {
        return accum;
    }
    if (typeof value === 'number') {
        return { ...accum, [key]: String(value) };
    }
    return { ...accum, [key]: value };
}, normalizeHttpParameters = (params: HttpParameters = {}): NormalizedHttpParameters => Object.entries(params).reduce(normalizeHttpParameter, {});
export const FORMS_DATA_CONFIG = new InjectionToken("ServiceDataHttpConfig");
@Injectable({
    providedIn: 'root'
})
export class FormsDataService {
    constructor(private readonly http: HttpClient, 
    @Inject(FORMS_DATA_CONFIG)
    private readonly config: ServiceDataHttpConfig) { }
    getFormsDocuments(params: {
        title?: string;
        formCategory?: string[];
        memberType?: string[];
        state?: string[];
        from?: number;
        size?: number;
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.FormsDocumentResponse>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/forms/documents`;
        return this.http.request<Data.FormsDocumentResponse>('get', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    postFormsDocumentsRecord(body: any, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.FormsDocumentDetails>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/forms/documents`;
        return this.http.request<Data.FormsDocumentDetails>('post', uri, {
            body,
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    putFormsDocumentsRecord(body: Data.FormsDocumentDetails, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.FormsDocumentDetails>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/forms/documents`;
        return this.http.request<Data.FormsDocumentDetails>('put', uri, {
            body,
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    deleteFormsDocumentsRecord(params: {
        name: string;
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.SuccessResponse>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/forms/documents`;
        return this.http.request<Data.SuccessResponse>('delete', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    postFormsDocumentsRecordWithProgress(body: any, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpEvent<Data.FormsDocumentDetails>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/forms/documents`;
        return this.http.request<Data.FormsDocumentDetails>('post', uri, {
            body,
            headers,
            observe: 'events',
            responseType: 'json',
            withCredentials: false,
        });
    }
    getFormsDocumentsRecordByTitleUrl(params?: undefined, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<any>> {
        //return `${this.config.apiRoot}${this.config.servicePath}/${version}/forms/documents/details?title=${title}`;
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/forms/documents/details`;
        return this.http.request<Blob>('get', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'blob' as 'json',
            withCredentials: false,
        });
    }
}
interface NormalizedHttpParameters {
    [k: string]: string | string[];
}
interface HttpParameters {
    [k: string]: string | string[] | undefined | number;
}
