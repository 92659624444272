import { createMocks } from '@backbase/foundation-ang/data-http';
import { Provider } from '@angular/core';
import { SEC_DATA_CONFIG } from './sec-data.service';
const examples = [
    {
        "urlPattern": "/{version}/sec/details",
        "method": "GET",
        "responses": []
    },
    {
        "urlPattern": "/{version}/sec/details/zipContents",
        "method": "GET",
        "responses": []
    }
];
export const SecDataMocksProvider: Provider = createMocks(examples, SEC_DATA_CONFIG);
