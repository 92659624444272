import { Component, OnInit } from '@angular/core';
import { WidgetFhlbInvestorSecNgService } from './widget-fhlb-investor-sec-ng.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';
import { ItemModel } from '@backbase/core-ang';

@Component({
  selector: 'fhlb-widget-fhlb-investor-sec-filling-ng',
  templateUrl: "./../investor-page.html",
  styles: []
})
export class WidgetFhlbInvestorSecFillingNgComponent implements OnInit {

  secFilingList:any;
  showErrorMessage:any;
  constructor(private investorDataService: WidgetFhlbInvestorSecNgService, private model: ItemModel,private cookieService: CookieService) { }



  ngOnInit() {

        // API to get particular Advance.
        let xsrfToken = this.cookieService.get('XSRF-TOKEN');
        let headers= new HttpHeaders();
        let params ={}
        headers = headers.append('X-XSRF-TOKEN', xsrfToken);
        this.secFilingList = this.investorDataService.getSecListingList(params, headers);
    this.showErrorMessage = this.investorDataService.currentErrorMessage;
  }
}

