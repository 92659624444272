/**
* Write a basic business logic to fetch and save data.
* This logic is usually encapsulated in a widget service.
* The widget service layer is actually the only place where we deal with the data module.
* Let's first have a look at the interface provided by the data service.
* 1.It take parameters for each of the placeholders defined in the corresponding part of the spec.
* 2.It return an Observable of HttpResponse of one of the previously defined data types.
* 3.Each method has the ability to optionally accept additional request headers and/or request body for more flexibility
*/
import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {
  AdvanceDetailsGET,
  AdvanceDetails as AdvanceDataItem,
  AdvanceDataService,
} from '@backbase/advance-data';

export interface AdvanceDetails {
  productName: string;
  description?: string;
  fundingGoal?: Array<string>;
  fundingType?: string;
  termLimitations?: string;
  minimumAmount?: string;
  rateOutlook?: Array<string>;
  availability?: string;
  prepaymentEligible?: boolean;
  putable?: boolean;
  interestPayments?: string;
  principalDue?: string;
}

@Injectable()
export class WidgetFhlbankAdvancePageNgService {

  /**
   * Filter List in Narrow list block of Advance list view
   */
  narrowList = [
    { "fundingGoal": ["Covers short-term funding gaps", "Funds mortgage assets", "Manages prepayment risks", "Match funds adjustable-rate assets", "Match funds fixed-rate assets", "Protects against falling rates", "Protects against rising rates", "Reduces funding costs"] },
    { "fundingType": ["Fixed", "Floating"] },
    { "rateOutlook": ["Steepening", "Rising", "Flat", "Flattening", "Falling"] },
    { "putable": ["Yes", "No"] }
  ]

  constructor(private readonly data: AdvanceDataService) { }
  errorMessage = new Subject<boolean>();
  currentErrorMessage = this.errorMessage.asObservable();

  totalCount = new Subject<any>();
  currentTotalCount = this.totalCount.asObservable();

  /**
   * To get Advance list based on filter criteria
   * Leaving it empty will fetch all the products
   */
  getItems(advanceParam: any, headers: HttpHeaders): Observable<AdvanceDetails> {
    return this.data.getAdvancesDetails(advanceParam, headers).pipe(map((response: HttpResponse<AdvanceDetailsGET>): any => {
      this.errorMessage.next(false);
      this.totalCount.next(response.body!.advanceProducts!.length);
      var advanceResponse =response.body ? response.body.advanceProducts : [];
      var totalAdvanceCount = advanceResponse ? advanceResponse.length : [];
      this.totalCount.next(totalAdvanceCount);
      return advanceResponse;
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
      this.totalCount.next(0);
      return of();
    }));
  }


}