/**
* Wrapped data endpoints in corresponding methods in the data-module service are available.
1. Data interfaces are declared (basically it's a set of a wire types for the widget), so the data format is clearly defined.
2. Ability to mock the data (based on examples provided within RAML specification).
3. Data-model extensions technique, which allows you to pass extra data from the backend (i.e. custom fields on top of the default API contract).
* @author -Mindtree
*/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { createServiceDataHttpConfig, DATA_HTTP_CONFIG, DataHttpModule, ServiceDataHttpConfig } from "@backbase/foundation-ang/data-http";
import { ADVANCE_DATA_CONFIG, AdvanceDataService } from "./advance-data.service";
export const CONFIG_VALUE = new InjectionToken("ServiceDataHttpConfig");
@NgModule({
    imports: [
        CommonModule,
        DataHttpModule,
        HttpClientModule
    ],
    declarations: [],
    providers: [AdvanceDataService, { provide: CONFIG_VALUE, useValue: {
            servicePath: '/advance-presentation-service',
        }}, {
           provide: ADVANCE_DATA_CONFIG,
           useFactory: createServiceDataHttpConfig,
           deps: [DATA_HTTP_CONFIG, CONFIG_VALUE],
        }]
})
export class AdvanceDataModule {
    static forRoot(config: Partial<ServiceDataHttpConfig>): ModuleWithProviders {
        return {
            ngModule: AdvanceDataModule,
            providers: [
                {
                    provide: CONFIG_VALUE,
                    useValue: config,
                },
            ],
        };
    }
}
