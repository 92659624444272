import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { WidgetFhlbNewsNgService } from './widget-fhlb-news-ng.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

declare var $: any;
@Component({
  selector: 'fhlb-widget-fhlb-news-ng',
  templateUrl: "./../news-page.html",
  styles: []
})
export class WidgetFhlbNewsNgComponent implements OnInit, AfterViewChecked {

  newsList: any;
  newsParams: any = {};
  showErrorMessage: any;
  pagenationDetails: any = {}
  totalNewsCount:any;

  public width = 0;
  greaterwidth: boolean = false;

  constructor(private strategiesDataService: WidgetFhlbNewsNgService,private cookieService: CookieService) { }

  ngOnInit() {
    this.getselectedfilter(this.newsParams);
  }

  /**
* Geting Advance list based on the filter Params.
* @param filterParams 
*/
  getselectedfilter(filterParams: any) {
    Object.assign(filterParams, { "size": 6 });
    Object.assign(filterParams, { "contentType": "NEWS" });

    Object.assign(this.pagenationDetails, { "size": 6 });
    if (filterParams.from)
      Object.assign(this.pagenationDetails, { "from": filterParams.from });
    else
      Object.assign(this.pagenationDetails, { "from": 0 });
    this.newsParams = filterParams;
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.newsList = this.strategiesDataService.getNewsList(filterParams, headers);
    this.showErrorMessage = this.strategiesDataService.currentErrorMessage;
    this.totalNewsCount = this.strategiesDataService.currentTotalCount;
  }

  public ngAfterViewChecked(): void {

    /* need _canScrollDown because it triggers even if you enter text in the textarea */
    if ($(".news-events-media-wrapper").length > 0) {
      $(".news-editable-templates > div")
        .children()
        .appendTo(".news-mapping");
    }
  }


}
