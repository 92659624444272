import { Component, OnInit } from '@angular/core';
import { WidgetFhlbStrategiesNgService } from './widget-fhlb-strategies-ng.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'fhlb-widget-fhlb-strategies-ng',
  templateUrl: "./../strategies-page.html",
  styles: []
})
export class WidgetFhlbStrategiesNgComponent implements OnInit {
  strategiesList: any;
  strategiesParams: any = {};
  showErrorMessage: any;
  totalSICount:any;
  pagenationDetails: any = {}

  constructor(private strategiesDataService: WidgetFhlbStrategiesNgService,private cookieService: CookieService) { }

  ngOnInit() {
    this.getselectedfilter(this.strategiesParams);
  }

  /**
 * Geting Advance list based on the filter Params.
 * @param filterParams 
 */
  getselectedfilter(filterParams: any) {
    Object.assign(filterParams, { "isListStrategy": "Y" });
    Object.assign(filterParams, { "size": 8 });
    Object.assign(this.pagenationDetails, { "size": 8 });
    if (filterParams.from)
      Object.assign(this.pagenationDetails, { "from": filterParams.from });
    else
      Object.assign(this.pagenationDetails, { "from": 0 });
    this.strategiesParams = filterParams;
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.strategiesList = this.strategiesDataService.getStrategiesList(filterParams, headers);
    this.showErrorMessage = this.strategiesDataService.currentErrorMessage;
    this.totalSICount = this.strategiesDataService.currentTotalCount;
  }
}
