import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, Output, EventEmitter, AfterViewChecked } from '@angular/core';
import { CompleterService, CompleterData, RemoteData } from 'ng2-completer';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'fhlb-search-query',//fhlb-search-list
  templateUrl: "./../search-query.html"
})
export class FhlbankSearchQueryComponent implements OnInit, AfterViewChecked {
  @Input() searchFilterParams: any;
  @Input() suggestformaturl: any;
  @Output() selectedfiltername = new EventEmitter();
  errorMessage: boolean = false;
  SuggestdataService: RemoteData;
  paramsObject: any = {};
  http: any;


  protected suggestID: string = "";

  //protected suggestionResult = ['James T. Kirk', 'Benjamin Sisko', 'Jean-Luc Picard', 'Spock', 'Jonathan Archer', 'Hikaru Sulu', 'Christopher Pike', 'Rachel Garrett' ];




  onKeyup(event: any) {
    if (event.key == "Enter") {
      this.searchQuery(this.suggestID);
    }
  }

  onClick(event: any) {
    if (event.target.textContent != "") {
      this.searchQuery(this.suggestID);
    }
  }

  searchQuery(e: any) {
    this.paramsObject = {};
    Object.assign(this.paramsObject, { "query": e });
    Object.assign(this.paramsObject, { "suggestion": false });
    this.selectedfiltername.emit(this.paramsObject);
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  constructor(private cd: ChangeDetectorRef, private completerService: CompleterService,private cookieService: CookieService) {
    this.SuggestdataService = completerService.remote(null);

    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
let headers= new HttpHeaders();
headers = headers.append('X-XSRF-TOKEN', xsrfToken);

    this.SuggestdataService.urlFormater(term => {
      const url = this.suggestformaturl + "query=" + term + "&portalContext=fhlbank-boston"
      return url;
    });
    let options = {
      headers:  headers
   }
    this.SuggestdataService.requestOptions(options);
    this.SuggestdataService.dataField('suggestResultItems');
  }
  ngOnInit() {
    if (this.searchFilterParams.query != null) {
      this.suggestID = this.searchFilterParams.query;
    }

  }

  public ngAfterViewChecked(): void {
    console.log("search result page box rendered")
    if ($(".search-box > .completer-holder").length > 0) {
      $(".search-box > .completer-holder").addClass('search-result-box-parent-wrapper');
      if ($(".search-box > .search-result-box-parent-wrapper").length === 1) {

        if ($(".search-box > .search-result-box-parent-wrapper > label").length === 0) {
          $("<label for='search-result-box' class='ax-hidden'>Search Your Query</label>").insertBefore("input");
          $(".search-box > .search-result-box-parent-wrapper input").attr('id', 'search-result-box');
          $(".search-box > .search-result-box-parent-wrapper input").attr('name', 'search-result-box');
          $(".search-box > .search-result-box-parent-wrapper input").attr('aria-labelledby', 'search-result-box');
        }
      }
    }
  }
}