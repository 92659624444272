import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FhlbLoaderComponent } from './fhlb-loader.component';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ FhlbLoaderComponent ],
  exports: [ FhlbLoaderComponent ]
})
export class FhlbLoaderModule { }
