import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { WidgetFhlbRatesNgService } from './widget-fhlb-rates-ng.service';
import { ItemModel } from '@backbase/core-ang';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'fhlb-widget-fhlb-rates-page-ng',
  templateUrl: "./../rates-page.html",
  styles: []
})
export class WidgetFhlbRatesPageNgComponent implements OnInit {

  ratesList!: any;
  showErrorMessage: any;
  defaultParams: any;
  productName: any;
  rateType: any;
  ratesParams: any = {};
  values: any;
  downloadPath: any;
  downloadFailed: boolean = false;
  downloadBoolean: any;
  rateOptions: any;
  responseStatus:any = 200;


  constructor(private ratesService: WidgetFhlbRatesNgService, private model: ItemModel, 
    private cd: ChangeDetectorRef,private cookieService: CookieService) { }

  ngOnInit() {
    this.downloadBoolean = false;
    this.ratesParams = {};
    this.defaultParams = {};
    this.model.property("rateType").subscribe(
      (rateType) => {
        this.rateType = rateType;
        // Get the Product Name from preference to display rates.
        if (this.rateType === "rates-table") {
          this.model.property("productName").subscribe(
            (productName) => {
              this.productName = productName;
              this.ratesParams.productName = productName;
              this.getRatesDetails(this.ratesParams);
              // Default Terms Year from Preference to get the initial API.
            });
        }else{
          let xsrfToken = this.cookieService.get('XSRF-TOKEN');
          let headers= new HttpHeaders();
          headers = headers.append('X-XSRF-TOKEN', xsrfToken);
          this.responseStatus= this.ratesService.getRatesHealth(this.defaultParams, headers);
        }
      });
    //
    this.showErrorMessage = this.ratesService.currentErrorMessage;
    this.downloadBoolean = this.ratesService.currentDownloadErrorMessage;
    

  }

  /**
   * Function to get the Rates Details from API.
   * @param selectedParams 
   */
  getRatesDetails(selectedParams: any) {
    Object.assign(selectedParams, { "productName": this.productName });
    Object.assign(selectedParams, { "isHistoricalPage": "N" });
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.ratesList = this.ratesService.getRates(selectedParams, headers);
    this.showErrorMessage = this.ratesService.currentErrorMessage;
  }

  /**
   * Function to get the selected terms from the dropdown. 
   * @param rateDetails 
   */
  getRatesList(rateDetails: any) {
    this.getRatesDetails(rateDetails);
  }

  errorMessageDisappear() {
    setTimeout(() => {
      this.ratesService.getdownLoadBoolean(false);
      this.cd.markForCheck();
    }, 5000);

  }

  getDownloadTerms(rateFormat: any) {
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.downloadPath = this.ratesService.getDownloadRatesURL(rateFormat, headers).subscribe(
      (response: any) => {
      var filename = response.headers.get("Content-Disposition").split("filename=")[1];
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(response.body,filename);
                    }  else {
        var url = window.URL.createObjectURL(response.body);
        // Default Terms Year from Preference to get the initial API.
          var a: any = document.createElement('A');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          }
      },
      (error) => {
        this.ratesService.getdownLoadBoolean(true);
        this.errorMessageDisappear();
      });
      
  }

  checkDownloadStatus(path: any): Observable<string> {
    var url = path;
    return Observable.create((observer: { next: (arg0: any) => void; complete: () => void; error: (arg0: any) => void; }) => {
      var xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(xhr.response);
            observer.complete();
          } else {
            observer.error(true);
          }
        }
      };
      xhr.send();
    });
  }

}
