import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { WidgetFhlbRatesNgService } from './widget-fhlb-rates-ng.service';


@Component({
  selector: 'fhlb-rates-static-header',
  templateUrl: "./../rates-static-content.html",
  styles: []
})
export class FhlbankRatesStaticHeaderComponent implements OnInit {
  @Input() ratesList: any;

  ngOnChanges(changes: SimpleChanges) {
  }

  constructor(private ratesService: WidgetFhlbRatesNgService) {
  }

  ngOnInit() {
  }
}
