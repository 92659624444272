import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { WidgetFhlbMapsNgComponent } from './widget-fhlb-maps-ng.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbMapsNgComponent }
    })
  ],
  declarations: [WidgetFhlbMapsNgComponent]
})
export class WidgetFhlbMapsNgModule { }
