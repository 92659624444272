import { Component, OnInit} from '@angular/core';
import { WidgetFhlbSearchHeaderNgService } from './widget-fhlb-search-header-ng.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'fhlb-publicportal-app',
  //template: '<bb-root></bb-root>'
  templateUrl: "./search-header-page.html",
})
export class AppComponent implements OnInit {
  suggestURL: any;
  searchParams: any = {};
  showErrorMessage: any;
  pagenationDetails: any = {}

  constructor(private searchDataService: WidgetFhlbSearchHeaderNgService,private cookieService: CookieService) { }

  ngOnInit() {
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.suggestURL = this.searchDataService.getSuggestsURL(this.searchParams,headers);
  }

}
