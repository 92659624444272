import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { Inject, InjectionToken } from "@angular/core";
import { ServiceDataHttpConfig } from "@backbase/foundation-ang/data-http";
import * as Data from "./si-news-events-data.interfaces";
const version = 'v1', normalizeHttpParameter = (accum: NormalizedHttpParameters, [key, value]: [string, string | string[] | undefined | number]) => {
    if (value === undefined) {
        return accum;
    }
    if (typeof value === 'number') {
        return { ...accum, [key]: String(value) };
    }
    return { ...accum, [key]: value };
}, normalizeHttpParameters = (params: HttpParameters = {}): NormalizedHttpParameters => Object.entries(params).reduce(normalizeHttpParameter, {});
export const SI_NEWS_EVENTS_DATA_CONFIG = new InjectionToken("ServiceDataHttpConfig");
@Injectable({
    providedIn: 'root'
})
export class SiNewsEventsDataService {
    constructor(private readonly http: HttpClient, 
    @Inject(SI_NEWS_EVENTS_DATA_CONFIG)
    private readonly config: ServiceDataHttpConfig) { }
    getStrategiesDetails(params: {
        contentType?: string[];
        fundingGoals?: string[];
        memberTypes?: string;
        contentTitle?: string;
        isListStrategy?: string;
        from?: number;
        size?: number;
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.StrategiesContentDetailsGET>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/strategies/details`;
        return this.http.request<Data.StrategiesContentDetailsGET>('get', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    postStrategiesAddRecord(body: Data.StrategiesContentDetailsPOST, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.ContentDetailsRESPONSE>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/strategies/add`;
        return this.http.request<Data.ContentDetailsRESPONSE>('post', uri, {
            body,
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    putStrategiesUpdateRecord(body: Data.StrategiesContentDetailsPUT, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.ContentDetailsRESPONSE>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/strategies/update`;
        return this.http.request<Data.ContentDetailsRESPONSE>('put', uri, {
            body,
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    deleteStrategiesDeleteRecord(params: {
        contentIds: string[];
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.ContentDetailsRESPONSE>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/strategies/delete`;
        return this.http.request<Data.ContentDetailsRESPONSE>('delete', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    getNewsDetails(params: {
        articleDate?: string[];
        from?: number;
        size?: number;
        contentType: string;
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.NewsContentDetailsGET>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/news/details`;
        return this.http.request<Data.NewsContentDetailsGET>('get', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    postNewsAddRecord(body: Data.NewsContentDetailsPOST, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.ContentDetailsRESPONSE>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/news/add`;
        return this.http.request<Data.ContentDetailsRESPONSE>('post', uri, {
            body,
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    putNewsUpdateRecord(body: Data.NewsContentDetailsPUT, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.ContentDetailsRESPONSE>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/news/update`;
        return this.http.request<Data.ContentDetailsRESPONSE>('put', uri, {
            body,
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
    deleteNewsDeleteRecord(params: {
        contentTitle: string[];
        contentType: string;
    }, headers: HttpHeaders = new HttpHeaders({})): Observable<HttpResponse<Data.ContentDetailsRESPONSE>> {
        const uri = `${this.config.apiRoot}${this.config.servicePath}/${version}/news/delete`;
        return this.http.request<Data.ContentDetailsRESPONSE>('delete', uri, {
            params: normalizeHttpParameters(params),
            headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: false,
        });
    }
}
interface NormalizedHttpParameters {
    [k: string]: string | string[];
}
interface HttpParameters {
    [k: string]: string | string[] | undefined | number;
}
