import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { WidgetFhlbInvestorSecFillingNgComponent } from './widget-fhlb-investor-sec-filling-ng.component';
import { FhlbankInvestorSecListComponent } from './fhlbank-investor-sec-list.component';
import{WidgetFhlbInvestorSecNgService}  from './widget-fhlb-investor-sec-ng.service';
import { FhlbUiComponentsLibModule } from '@backbase/fhlb-ui-components-lib';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    FhlbUiComponentsLibModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbInvestorSecFillingNgComponent }
    })
  ],
  declarations: [WidgetFhlbInvestorSecFillingNgComponent, FhlbankInvestorSecListComponent],
  providers: [WidgetFhlbInvestorSecNgService]
})
export class WidgetFhlbInvestorSecFillingNgModule { }