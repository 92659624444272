import { createMocks } from '@backbase/foundation-ang/data-http';
import { Provider } from '@angular/core';
import { RATES_DATA_CONFIG } from './rates-data.service';
const examples = [
    {
        "urlPattern": "/{version}/rates",
        "method": "GET",
        "responses": [
            {
                "status": 200,
                "body": {
                    "name": "LONG TERM",
                    "effectiveDate": "2019-10-02",
                    "effectiveTime": "3:57 PM",
                    "rates": [
                        {
                            "term": "1.25 yr",
                            "reg": 1.95,
                            "cda": 1.8,
                            "cdaExtra": 1.76,
                            "nef": 1.93
                        }
                    ],
                    "terms": [
                        {
                            "term": "1.25 yr"
                        },
                        {
                            "term": "1.5 yr"
                        }
                    ],
                    "maturity": []
                },
                "type": "TransactionsGet"
            }
        ]
    },
    {
        "urlPattern": "/{version}/ratesDownload",
        "method": "GET",
        "responses": [
            {
                "status": 200,
                "body": null,
                "type": "any"
            }
        ]
    },
    {
        "urlPattern": "/{version}/checkBusinessDay",
        "method": "GET",
        "responses": [
            {
                "status": 200,
                "body": {
                    "businessday": {
                        "asOfDate": "2001-07-04",
                        "isBusinessDay": false
                    }
                },
                "type": "BusinessdayResponse"
            }
        ]
    }
];
export const RatesDataMocksProvider: Provider = createMocks(examples, RATES_DATA_CONFIG);
