import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';


 import { SearchDataService, SearchResponse, SuggestResponse} from '@backbase/search-data';

@Injectable()
export class WidgetFhlbSearchNgService {

  errorMessage = new Subject<boolean>();
  currentErrorMessage = this.errorMessage.asObservable();

  constructor(private readonly data: SearchDataService,private cookieService: CookieService) { }


  /**
   * This function will return the Search for that Product when we pass the product name as a parameter.
   * @param searchParams 
   */
  getSearchList(SearchParams: any, headers: HttpHeaders): Observable<any> {
    return this.data.getSearch(SearchParams, headers).pipe(map((response: HttpResponse<SearchResponse>): any => {
      this.errorMessage.next(false);
      return response
      ? response : [];
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
        return of();
    }));
  }

    /**
   * This function will return the Search for that Product when we pass the product name as a parameter.
   * @param searchParams 
   */
  getSuggestList(SearchParams: any, headers: HttpHeaders): Observable<any> {
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let newheaders = new HttpHeaders();
    newheaders = newheaders.append('X-XSRF-TOKEN', xsrfToken);
    return this.data.getSuggest(SearchParams, newheaders).pipe(map((response: HttpResponse<SuggestResponse>): any => {
      this.errorMessage.next(false);
      return response.body
      ? response.body.suggestResultItems : [];
    })
    ).pipe(catchError(err => {
      this.errorMessage.next(true);
        return of();
    }));
  }

  getSuggestsURL(ratesParams: any, headers: HttpHeaders) {
    return this.data.getSuggestURL(ratesParams, headers) ;
  }

}

