import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { WidgetFhlbFormsPageNgComponent } from './widget-fhlb-forms-page-ng.component';
import {FhlbankFormsFilterComponent } from './fhlbank-forms-filter.component';
import { FhlbankFormsListComponent} from './fhlbank-forms-list.component';
import{WidgetFhlbFormsPageNgService}  from './widget-fhlb-forms-page-ng.service';
import { FhlbUiComponentsLibModule } from '@backbase/fhlb-ui-components-lib';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,FormsModule,
    HttpClientModule,
    FhlbUiComponentsLibModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbFormsPageNgComponent }
    })
  ],
  declarations: [WidgetFhlbFormsPageNgComponent, FhlbankFormsFilterComponent, FhlbankFormsListComponent],
  providers: [WidgetFhlbFormsPageNgService]
})
export class WidgetFhlbFormsPageNgModule { }
