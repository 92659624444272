import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';


@Component({
  selector: 'fhlb-more-strategies-list',
  templateUrl: "./../more-strategies-results.html"
})
export class FhlbankMoreStrategiesListComponent implements OnInit {
  @Input() moreStrategiesList: any = [];
  errorMessage: boolean = false;
  strategiesImages: any = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes['moreStrategiesList']) {
      // Verify Any strategies Available for applied filter.
      if (this.moreStrategiesList != null) {
        if (this.moreStrategiesList.length == 0) {
          this.errorMessage = true;
        } else {
          this.errorMessage = false;
        }
      }
    }
  }

  /**
   * Function to display the date and time in formatted 
   * @param effectiveDate 
   */
  getFormatedDate(effectiveDate: any) {
    var dateFormat = require('dateformat');
    var d = new Date(effectiveDate);
    return dateFormat(d, "mmmm dd, yyyy");
  }
 /**
   * On Selecting the Strategies Heading, we need to redirect to that particular page.
   * @param StrategiesName
   */
  getMoreStrategiesURL(StrategiesName: any) {
    // forming the Page URL.
    var StrategyURL = window.BB.config.linkRoot + "/" + window.BB.config.portalName + "/" + StrategiesName.contentTitle.toLowerCase().replace(/[^a-zA-Z-._0-9 ]/g, "").replace(/[ ]/g,"-").replace(/--+/g, '-');
     window.location.href = StrategyURL;
    //window.open(StrategyURL, '_blank');

  }

  constructor(private cd: ChangeDetectorRef) { }
  ngOnInit() {
    //To get the images from Content Repository. 
    this.strategiesImages = {
      "Presentation": "/api/contentservices/api/contentstream/fhlbank-boston/icon_presentation.svg",
      "Article": "/api/contentservices/api/contentstream/fhlbank-boston/icon_article.svg",
      "Infographic": "/api/contentservices/api/contentstream/fhlbank-boston/	icon_infographic.svg",
      "Video": "/api/contentservices/api/contentstream/fhlbank-boston/icon_video.svg",
      "Webinar": "/api/contentservices/api/contentstream/fhlbank-boston/icon_webinar.svg"
    }

  }

  /**
     * On Selecting the Strategies Heading, we need to redirect to that particular page.
     * @param StrategiesName 
     */
  getStrategiesURL(StrategiesName: any) {
    // forming the Page URL.
    var StrategyURL = window.BB.config.linkRoot + "/" + window.BB.config.portalName + "/" + StrategiesName.contentTitle.toLowerCase().replace(/[^a-zA-Z-._0-9 ]/g, "").replace(/[ ]/g,"-").replace(/--+/g, '-');
    window.location.href = StrategyURL;

  }

}
@Pipe({ name: 'removeUnderscore' })

export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/ /g, "-");
  }
}

