import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fhlb-widget-fhlb-header-login-ng',
  templateUrl: '../index.html',
  styles: []
})
export class WidgetFhlbHeaderLoginNgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
