/**
* Wrapped data endpoints in corresponding methods in the data-module service are available.
1. Data interfaces are declared (basically it's a set of a wire types for the widget), so the data format is clearly defined.
2. Ability to mock the data (based on examples provided within RAML specification).
3. Data-model extensions technique, which allows you to pass extra data from the backend (i.e. custom fields on top of the default API contract).
* @author -Mindtree
*/

import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { WidgetFhlbankAdvancePageNgComponent } from './widget-fhlbank-advance-page-ng.component';
import { AdvanceDataModule } from '@backbase/advance-data';
import { WidgetFhlbankAdvancePageNgService } from './widget-fhlbank-advance-page-ng.service';
import { FhlbankAdvanceFilterComponent } from './fhlbank-advance-filter.component';
import { FhlbankAdvanceListComponent } from './fhlbank-advance-list.component';
import { HttpClientModule } from '@angular/common/http';
import { FhlbUiComponentsLibModule } from '@backbase/fhlb-ui-components-lib';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    AdvanceDataModule,
    RouterModule.forRoot([], { initialNavigation: false, useHash: true, enableTracing: false }),
    FormsModule,
    HttpClientModule,
    FhlbUiComponentsLibModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbankAdvancePageNgComponent }
    })
  ],
  declarations: [WidgetFhlbankAdvancePageNgComponent, FhlbankAdvanceFilterComponent, FhlbankAdvanceListComponent],
  providers: [WidgetFhlbankAdvancePageNgService],
  entryComponents: [WidgetFhlbankAdvancePageNgComponent],
})
export class WidgetFhlbankAdvancePageNgModule { }
