import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FhlbUiComponentsLibModule } from '@backbase/fhlb-ui-components-lib';
import { BrowserModule } from '@angular/platform-browser';
import { WidgetFhlbSearchNgComponent } from './widget-fhlb-search-ng.component';
import { WidgetFhlbSearchNgService } from './widget-fhlb-search-ng.service';
import { FhlbankSearchListComponent} from './fhlbank-search-list.component';
import { FhlbankSearchQueryComponent} from './fhlbank-search-query.component';
import { Ng2CompleterModule } from "ng2-completer";


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    FhlbUiComponentsLibModule,
    Ng2CompleterModule,
    BrowserModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbSearchNgComponent }
    })
  ],
  declarations: [WidgetFhlbSearchNgComponent, FhlbankSearchListComponent, FhlbankSearchQueryComponent],
  providers: [WidgetFhlbSearchNgService]
})
export class WidgetFhlbSearchNgModule { }
