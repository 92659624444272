import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { WidgetFhlbRatesNgService } from './widget-fhlb-rates-ng.service';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
  selector: 'fhlb-rates-two-dropdown-header',
  templateUrl: "./../rates-two-dropdown-content.html",
  styles: []
})
export class FhlbankRatesTwoDropdownHeaderComponent implements OnInit {
  @Input() rateListDetails: any;
  @Input() rateOption: any;
  @Output() selectedterm = new EventEmitter();
  selectedRateDetails: any;
  params: any;
  termsIndex: any;
  selectedTerm: any;
  selectedAmort: any;
  selectedMaturity: any;
  selectedPut: any;
  currentRates: any;

  form: FormGroup;

  /**
   * Function to display the date and time in formatted 
   * @param effectiveDate 
   */
  getFormatedDate(effectiveDate: any) {
    var dateFormat = require('dateformat');
    var d = new Date(effectiveDate);
    return dateFormat(d, "mmmm dd, yyyy");
  }

  /**
   * Function to get the result based on Selected Terms
   * @param selectedTerm 
   */
  onTermSelected(selectedTerm: any) {
    this.params = {}
    this.selectedTerm = selectedTerm.term;
    Object.assign(this.params, { "term": selectedTerm.term });
    this.selectedAmort = selectedTerm.amorts[0].amort;
    Object.assign(this.params, { "amort": selectedTerm.amorts[0].amort });
    this.selectedterm.emit(this.params);
  }

  onAmortSelected(selectedAmort: any) {
    this.params = {}
    this.selectedAmort = selectedAmort.amort;
    Object.assign(this.params, { "term": this.selectedTerm });
    Object.assign(this.params, { "amort": this.selectedAmort });
    this.selectedterm.emit(this.params);
  }

  onMaturitySelected(selectedMaturity: any) {
    this.params = {}
    this.selectedMaturity = selectedMaturity.maturity;
    Object.assign(this.params, { "maturity": selectedMaturity.maturity });
    this.selectedPut = selectedMaturity.puts[0].put;
    Object.assign(this.params, { "put": selectedMaturity.puts[0].put });
    this.selectedterm.emit(this.params);
  }

  onPutSelected(selectedPut: any) {
    this.params = {}
    this.selectedPut = selectedPut.put;
    Object.assign(this.params, { "maturity": this.selectedMaturity });
    Object.assign(this.params, { "put": this.selectedPut });
    this.selectedterm.emit(this.params);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rateListDetails']) {
    }

  }

  constructor() {
    this.form = new FormGroup({
      termlist: new FormControl(null),
      amortList: new FormControl(null),
      maturityList: new FormControl(null),
      putList: new FormControl(null),
    })

  }
  get termlist(): string {
    return this.form ? this.form.get('termlist')!.value : '';
  }
  get maturityList(): string {
    return this.form ? this.form.get('maturityList')!.value : '';
  }
  get amortList(): string {
    return this.form ? this.form.get('amortList')!.value : '';
  }
  get putList(): string {
    return this.form ? this.form.get('putList')!.value : '';
  }

  ngOnInit() {
    this.selectedRateDetails = this.rateListDetails[0];
    this.currentRates = this.selectedRateDetails.rates[0];
    if (this.rateOption == "amortizing") {
      this.selectedTerm = this.currentRates.term;
      this.selectedAmort = this.currentRates.amort;
      this.termsIndex = this.selectedRateDetails.terms.findIndex((x: { term: string; }) => x.term === this.selectedTerm)
      this.form.controls['termlist'].setValue(this.selectedRateDetails.terms[this.termsIndex], { onlySelf: true });
      var amortIndex = this.selectedRateDetails.terms[this.termsIndex].amorts.findIndex((x: { amort: string; }) => x.amort === this.selectedAmort);
      this.form.controls['amortList'].setValue(this.selectedRateDetails.terms[this.termsIndex].amorts[amortIndex], { onlySelf: true });
      
    }else{
      this.selectedMaturity = this.currentRates.maturity;
      this.selectedPut = this.currentRates.put;
      this.termsIndex = this.selectedRateDetails.maturity.findIndex((x: { maturity: string; }) => x.maturity === this.selectedMaturity)
      this.form.controls['maturityList'].setValue(this.selectedRateDetails.maturity[this.termsIndex], { onlySelf: true });
      var putIndex = this.selectedRateDetails.maturity[this.termsIndex].puts.findIndex((x: { put: string; }) => x.put === this.selectedPut);
      this.form.controls['putList'].setValue(this.selectedRateDetails.maturity[this.termsIndex].puts[putIndex], { onlySelf: true });
      
    }
  }
}
