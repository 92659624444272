/**
* Wrapped data endpoints in corresponding methods in the data-module service are available.
1. Data interfaces are declared (basically it's a set of a wire types for the widget), so the data format is clearly defined.
2. Ability to mock the data (based on examples provided within RAML specification).
3. Data-model extensions technique, which allows you to pass extra data from the backend (i.e. custom fields on top of the default API contract).
* @author -Mindtree
*/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BackbaseCoreModule } from '@backbase/foundation-ang/core';
import { WidgetFhlbankCompareAdvanceNgComponent } from './widget-fhlbank-compare-advance-ng.component';
import { WidgetFhlbankCompareAdvanceNgService } from './widget-fhlbank-compare-advance-ng.service';
import { AdvanceDataModule } from '@backbase/advance-data';
import { FhlbankCompareListComponent } from './fhlbank-compare-list.component';
import { FhlbUiComponentsLibModule } from '@backbase/fhlb-ui-components-lib';

@NgModule({
  imports: [
    CommonModule,
    AdvanceDataModule,
    FhlbUiComponentsLibModule,
    FormsModule,
    BackbaseCoreModule.withConfig({
      classMap: { WidgetFhlbankCompareAdvanceNgComponent }
    })
  ],
  declarations: [WidgetFhlbankCompareAdvanceNgComponent, FhlbankCompareListComponent],
  providers: [WidgetFhlbankCompareAdvanceNgService]
})
export class WidgetFhlbankCompareAdvanceNgModule { }
