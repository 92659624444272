import { Component, OnInit,ViewEncapsulation, Inject, ChangeDetectorRef } from '@angular/core';
import { WidgetFhlbHistoricalRatesNgService } from './widget-fhlb-historical-rates-ng.service';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'fhlb-widget-fhlb-historical-rates-ng',
  templateUrl: "./../historical-rates-page.html",
  styles: [],
  encapsulation: ViewEncapsulation.None
})


export class WidgetFhlbHistoricalRatesNgComponent implements OnInit {
  showErrorMessage: any;
  defaultParams: any;
  responseStatus:any = 200;
  downloadBoolean: any;
  downloadPath: any;
  constructor(private ratesService: WidgetFhlbHistoricalRatesNgService, private cd: ChangeDetectorRef,private cookieService: CookieService) { 
  }

  ngOnInit() {
    this.defaultParams = {};
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.responseStatus= this.ratesService.getRatesHealth(this.defaultParams, headers);
    this.showErrorMessage = this.ratesService.currentErrorMessage;
    this.downloadBoolean = this.ratesService.currentDownloadErrorMessage;
    
  }

  errorMessageDisappear() {
    setTimeout(() => {
      this.ratesService.getdownLoadBoolean(false);
      this.cd.markForCheck();
    }, 5000);

  }

  getDownloadTerms(rateFormat: any) {
    var rateService = this.ratesService;
    let xsrfToken = this.cookieService.get('XSRF-TOKEN');
    let headers= new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);
    this.downloadPath = this.ratesService.getDownloadRatesURL(rateFormat, headers).subscribe(
      (response: any) => {
     var filename = response.headers.get("Content-Disposition").split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(response.body,filename);
              }  else {
        var url = window.URL.createObjectURL(response.body);
        // Default Terms Year from Preference to get the initial API.
          var a: any = document.createElement('A');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
           }
      },
      (error) => {
        this.ratesService.getdownLoadBoolean(true);
        this.errorMessageDisappear();
      });
  }

  checkDownloadStatus(path: any): Observable<string> {
    var url = path;
    return Observable.create((observer: { next: (arg0: any) => void; complete: () => void; error: (arg0: any) => void; }) => {
      var xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(xhr.response);
            observer.complete();
          } else {
            observer.error(true);
          }
        }
      };
      xhr.send();
    });
  }
}
