import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'bb-fhlb-error-message-ui',
  templateUrl: './fhlb-error-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FhlbErrorMessageComponent implements OnInit {

  @Input() errorMessageContent:any;
  @Input() extraSpace:boolean=true;
  constructor() { }

  ngOnInit() { }
}