import { Component, OnInit } from '@angular/core';
import { WidgetFhlbFormsPageNgService } from './widget-fhlb-forms-page-ng.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'fhlb-widget-fhlb-forms-page-ng',
  templateUrl: "./../forms-page.html",
  styles: []
})
export class WidgetFhlbFormsPageNgComponent implements OnInit {
  formsList: any;
  formsParams: any = {};
  showErrorMessage: any;
  totalFormsCount:any;
  pagenationDetails: any = {}

  constructor(private formsDataService: WidgetFhlbFormsPageNgService,private cookieService: CookieService) { }

  ngOnInit() {
    this.formsParams = {};
    var filterArray = [];
    if (localStorage.getItem("formorapplicationFilter") !== null) {
      this.formsParams.formCategory = localStorage.getItem("formorapplicationFilter");
      localStorage.removeItem("formorapplicationFilter");
    }
    this.getselectedfilter(this.formsParams);
  }

    /**
 * Geting Advance list based on the filter Params.
 * @param filterParams 
 */
getselectedfilter(filterParams: any) {
  Object.assign(filterParams, { "size": 20 });
  Object.assign(this.pagenationDetails, { "size": 20 });
  if (filterParams.from)
    Object.assign(this.pagenationDetails, { "from": filterParams.from });
  else
    Object.assign(this.pagenationDetails, { "from": 0 });
  this.formsParams = filterParams;
  let xsrfToken = this.cookieService.get('XSRF-TOKEN');
let headers= new HttpHeaders();
headers = headers.append('X-XSRF-TOKEN', xsrfToken);
  this.formsList = this.formsDataService.getFormsList(filterParams,headers);
  this.showErrorMessage = this.formsDataService.currentErrorMessage;
  this.totalFormsCount = this.formsDataService.currentTotalCount;
}
}