import { Component, OnInit } from '@angular/core';
import { WidgetFhlbAdvancesFeaturesNgService, AdvanceDetails } from '../../widget-fhlb-advances-features-ng/src/widget-fhlb-advances-features-ng.service'
import { Observable } from 'rxjs';
import { ItemModel } from '@backbase/core-ang';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'fhlb-widget-fhlb-advances-header-ng',
  templateUrl: "./../advance-header-page.html",
  styles: []
})
export class WidgetFhlbAdvancesHeaderNgComponent implements OnInit {

  advanceDetails!: Observable<AdvanceDetails>;
  showErrorMessage: any;
  pageTitle: any;
  params: any = {};

  constructor(private advanceDataService: WidgetFhlbAdvancesFeaturesNgService, private model: ItemModel,private cookieService: CookieService) { }

  ngOnInit() {
    // To get the Title value to pass it to API for getting Advance Details.
    this.model.property("productName").subscribe(
      (title) => {
        var advanceparams = [];
        this.pageTitle = title;
        advanceparams.push(this.pageTitle);
        this.params.advanceNames = advanceparams;
        // API to get particular Advance.
        let xsrfToken = this.cookieService.get('XSRF-TOKEN');
        let headers= new HttpHeaders();
        headers = headers.append('X-XSRF-TOKEN', xsrfToken);
        this.advanceDetails = this.advanceDataService.getAdvanceDetails(this.params, headers);
      });
    this.showErrorMessage = this.advanceDataService.currentErrorMessage;
  }

}
